import React from 'react'
import UnlayerEditor from '../UnlayerEditor'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/EditOutlined'
import CopyIcon from '@material-ui/icons/FileCopyOutlined'
import Slide from '@material-ui/core/Slide'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CreatableSelect from 'react-select/creatable'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar'
import CodeMirrorEditor from '../../common/CodeMirrorEditor';

import { UserContext } from '../../../UserContext'
import { getTagOptions } from '../../common/constants'
import { copyToClipboard } from '../../../utils'


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  cardContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  flex: {
    flex: 1,
  },
  main: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  reactSelect: {
    zIndex: 1
  },
  formControl: {
    margin:"10px 0px",
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class Container extends React.Component {
  constructor(props) {
    super(props)
    
    const { template = {}, templatesCount, slugs } = this.props
    const json = Object.keys(template.json || {}).length > 0 ? template.json : null;
    
    this.state = {
      slug: template.slug,
      slugList: slugs,
      name: template.name || '',
      position: template.position !== null ? template.position : templatesCount + 1,
      json,
      tags: template.tags || [],
      showEditor: false,
      showCodeMirror: false,
      showAlert: false,
      alertData: {},
      stringifyedJson: json
    }
  }
  componentDidMount(){
      const templateSlugs = this.props.templates.filter(({slug}) => slug !== this.state.slug).map(({slug}) => slug)
      this.setState({ slugList: this.props.slugs.filter(value => !templateSlugs.includes(value)) })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  handleChange = (newValue, actionMeta) => {
    const tags = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ tags })
  };

  handleSave = (stringifyedJson) => {
    try {
      if (['null', '{}'].includes(stringifyedJson)) {
        throw 'JSON cannot be empty';
      }
      const parsedJson = JSON.parse(stringifyedJson);
      this.setState({ showCodeMirror: false, json: parsedJson });
    } catch (error) {
      this.setState({
        showAlert: true,
        alertData: {
          type: 'error', message: 'Invalid JSON'
        }
      });
    }
  };

  render() {
    const { classes, onSave } = this.props
    const { name, position, slug ,html, json, tags, showEditor, slugList, showCodeMirror, stringifyedJson, showAlert, alertData } = this.state
    let user = this.context
    const options = getTagOptions(user,'emails')
    const strJson = typeof(stringifyedJson) === 'string' ? stringifyedJson : JSON.stringify(stringifyedJson, null, 2);

    return (
      <div className={classes.main}>
        <form onSubmit={async(e) => {
          e.preventDefault()
          onSave({ name, position: parseInt(position), slug, json: stringifyedJson, html, tags })
        }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              name="name"
              value={this.state.name}
              onChange={(e) => { this.handleInputChange(e) }}
              autoFocus
            />
          </FormControl>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="position">Position</InputLabel>
            <Input
              id="position"
              name="position"
              value={this.state.position}
              onChange={(e) => { this.handleInputChange(e) }}
            />
          </FormControl>
        
          <FormControl required className={classes.formControl}>
            <InputLabel htmlFor="slug">Email Slug</InputLabel>
              <Select
                id="slug"
                value={slug === null ? 'none' : slug}
                onChange={event => this.setState({slug:event.target.value})}
                className={classes.selectEmpty}
              >
                <MenuItem value="none">
                  None (Unassigned)
                </MenuItem>
                {slugList?.map((item,index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
              </Select>
              <FormHelperText>Required</FormHelperText>
          </FormControl>

          <FormControl margin="normal" fullWidth classes={{ root: classes.reactSelect }}>
            <InputLabel className="MuiInputLabel-shrink" htmlFor="tags">Tags</InputLabel>
            <CreatableSelect
              isMulti
              onChange={this.handleChange}
              defaultValue={tags.map(item => ({ value: item, label: item }))}
              options={options}
              styles={{ 
                control: styles => ({ ...styles, 
                  margin: 0,
                  marginTop: 16,
                  border: 0,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                  borderRadius: 0,
                  minHeight: 32,
                  boxShadow: 'none',
                  ':hover': {
                    borderColor: 'rgba(0, 0, 0, 0.42)',
                  },
                }),
                container: styles => ({
                  fontFamily: 'sans-serif',
                  zIndex: 2,
                }),
                indicatorsContainer: styles => ({
                  display: 'none',
                }),
                placeholder: styles => ({
                  display: 'none',
                }),
              }}
            />
          </FormControl>

          <FormGroup row>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => { this.handleEditorOpen() }}
            >
              Edit Template
            </Button>
          </FormGroup>
        
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              style={{ marginRight: '20px' }}
              onClick={() => {
                copyToClipboard(strJson).then(() => {
                  this.setState({
                    showAlert: true,
                    alertData: {
                      type: 'success',
                      message: 'JSON Copied to Clipboard !'
                    }
                  });
                }).catch(() => {
                  this.setState({
                    showAlert: true,
                    alertData: {
                      type: 'error',
                      message: 'Error in copying to clipboard !'
                    }
                  });
                });
              }}
              startIcon={<CopyIcon />}>
                Copy JSON
            </Button>
            <Button
              onClick={() => this.setState({ showCodeMirror: true })}
              startIcon={<EditIcon />}>
                Edit JSON
            </Button>
          </div>

          <hr />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button>
        </form>
        
        <Dialog
          fullScreen
          open={this.state.showEditor}
          onClose={this.handleEditorClose}
          TransitionComponent={Transition}
        >
          <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleEditorClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Editor
              </Typography>
              <Button color="inherit" onClick={this.handleEditorSave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          
          {showEditor && <UnlayerEditor
            displayMode="email"
            json={json}
            closeEditor={this.handleEditorClose}
          />}
        </Dialog>

        <CodeMirrorEditor
          onEditorChanged={(editor, data, value) => this.setState({ stringifyedJson: value })}
          onEditorCancel={() => this.setState({ showCodeMirror: false, stringifyedJson: json })}
          onSave={() => this.handleSave(strJson)}
          stringifyedJson={strJson}
          isOpen={showCodeMirror} 
          transition={Transition}
          classes={classes}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert severity={alertData.type}>
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  
  handleEditorSave = () => {
    try {
      window.unlayer.exportHtml(data => {
        const { design, html } = data
        this.setState({ json: design, stringifyedJson: design, html })
        this.handleEditorClose()
      })
    } catch(err) {
      console.log("ERR", err)
      this.handleEditorClose()
    }
  }

  handleEditorClose = () => {
    this.setState({
      showEditor: false,
    })
  }

  handleEditorOpen = (page) => {
    this.setState({
      showEditor: true,
    })
  }
}


Container.contextType = UserContext
export default withStyles(styles)(Container)
