import React from 'react'
import { Link } from "react-router-dom"

import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const Container = styled.div`
  text-align: center;
  margin: 150px 0px;
  padding: 30px 0px;
`

export default class Dashboard extends React.Component {
  render() {
    const { partner: { partner: { name } } } = this.props
    return (
      <Container>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom>
          Welcome to {name} Partner Portal!
        </Typography>
      </Container>
    )
  }
}
