import React from 'react'

import gql from "graphql-tag"
import { Query, Mutation } from "react-apollo"

import Editor from './Editor'

import FETCH_LANDING_PAGE_TEMPLATES_COUNT from '../../../graphql/query/fetchLandingPageTemplatesCount'


const CREATE_LANDING_PAGE_TEMPLATE = gql`
  mutation CreateLandingPageTemplate($name: String!, $position: Int, $live: Boolean, $premium: Boolean, $pages: [CreatePageInput!]!, $tags: JSON ) {
    createLandingPageTemplate(input: { name: $name, position: $position, live: $live, premium: $premium, pages: $pages, tags: $tags }) {
      success
      landingPageTemplate {
        id
        name
        premium
        position
        live
        tags
        pages {
          id
          position
          unlayerId
          json
        }
      }
    }
  }
`;

export default class NewTemplate extends React.Component {
  render() {
    const { refetchTemplates, history } = this.props
    return (
      <Query query={FETCH_LANDING_PAGE_TEMPLATES_COUNT} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { totalLandingPageTemplatesCount: templatesCount } = data
          
          return (
            <Mutation mutation={CREATE_LANDING_PAGE_TEMPLATE}>
            {(createLandingPageTemplate, { error, loading, data }) => {
              //if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              // if (data && data.createLandingPageTemplate && data.createLandingPageTemplate.success) {
              //   refetchTemplates()
              //   return <Redirect to="/landing_pages" />
              // }
              
              return (
                <Editor templatesCount={templatesCount} onSave={(data,doRedirect=true) => {
                  createLandingPageTemplate({
                    variables: data,
                    refetchQueries: [
                      'getCurrentUser'
                    ]
                  }).then(({data}) => {
                    if (data && data.createLandingPageTemplate && data.createLandingPageTemplate.success) {
                      const { id } = data.createLandingPageTemplate.landingPageTemplate
                      if(doRedirect) {
                        history.push('/landing_pages')
                      }
                      else
                        history.push(`/landing_pages/${id}/edit`)
                    }
                  })
                }} />
              )
            }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}
