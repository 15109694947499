
import { Link } from "react-router-dom"

const actions = [
  { label: 'New Form', component: Link, to: `/form_templates/new` }
]

const filterBy = (widgetTypes) => [
  {
    name: 'name', label: 'Template name', type: 'textfield'
  },
  { name: 'tags', label: 'Tags', type: 'reactselect' },
  {
    name: 'widgetTypeId', label: 'Form Type', type: 'select',
    options: widgetTypes.map((item) => {
      return (
        { value: item.id, label: item.name }
      )
    })
  },
  {
    name: 'premium', label: 'Price', type: 'select',
    options: [
      { value: '', label: 'All' },
      { value: true, label:'Premium' },
      { value: false, label:'Free' }
    ]
  },
  { 
    name: 'live', label: 'Status', type: 'select',
    options: [
      { value: '', label: 'All' },
      { value: true, label: 'Live' },
      { value: false, label: 'Inactive'}
    ]
  },
  { 
    name: 'designFormat', label: 'Type', type: 'select', 
    options: [
      { value: '', label: 'All' },
      { value: 'SIMPLE', label: 'Simple' },
      { value: 'DRAG_DROP', label: 'Drag & Drop'}
    ]
  }
]

const sortBy = [
  {
    label: 'Sort By', type: 'select', 
    options: [
      { value: 'POSITION', label: 'Position' },
      { value: 'NAME', label: 'Name' },
      { value: 'RECENT', label: 'Most Recent'}
    ]
  }
]

export { 
  actions, sortBy, filterBy
}