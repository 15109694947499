export default {
  'email': {
    'url': ''
  },
  'square': {
    'url': ''
  },
  'favicon': {
    'url': ''
  },
  'full_black': {
    'url': ''
  },
  'full_white': {
    'url': ''
  },
  'logo_branding': {
    'url': ''
  }
}
