import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, CircularProgress } from '@material-ui/core'

const MyCheckBox = (props) => {

  const { label, name, value, callback, loading, useNumeric = true } = props
  const [input, setInput] = useState(value === true || value === '1')
  
  const onChange = (e) => {
    const { checked } =  e.target
    setInput(checked)
    callback && callback({[name]: useNumeric ? checked ? '1' : '0': checked})
  }

  useEffect(() => {
    setInput(value === true || value === '1')
  },[value])

  return (
    <FormControlLabel style = {{ width: '100%'}}
      control={
        loading ? 
        <CircularProgress style={{ width: 24, height: 24, padding: 9 }}/> :
        <Checkbox
          checked={input}
          onChange={onChange}
          name={name}
        />
      }
      label={label}
    />
  )
}
export default MyCheckBox