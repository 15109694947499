import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

const DeleteModal = ({ name, open, handleClose, deleteTag, error }) => {
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Delete {name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {name.toLowerCase()}? This will not affect your templates.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {error && <span style={{position: "relative",color: "red",right: "36%", fontFamily:"roboto", fontSize:14}}>Cannot delete, Slug already in use!</span>}
          <Button onClick={deleteTag} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default DeleteModal;