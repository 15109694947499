import gql from "graphql-tag"

const UPDATE_LANDING_PAGE_TEMPLATE = gql`
  mutation UpdateLandingPageTemplate($id: Int!, $name: String, $position: Int, $pages: [UpdatePageInput], $live: Boolean, $premium: Boolean, $tags: JSON, $screenshotUrl: String) {
    updateLandingPageTemplate(input: { id: $id, name: $name, position: $position, pages: $pages, live: $live, premium: $premium, tags: $tags, screenshotUrl: $screenshotUrl}) {
      success
      error
      landingPageTemplate {
        id
        name
        premium
        position
        live
        screenshotUrl
        tags
        pages {
          id
          position
          unlayerId
          json
        }
      }
    }
  }
`

export default UPDATE_LANDING_PAGE_TEMPLATE