import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Table from './Table';
import SlugsTable from './SlugsTable'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 30,
  },
  heading: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    marginTop: 10,
  },
  container: {
    ...theme.container,
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0px',
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: 800,
  },
  tab: {
    '& > * > * > *': {
      justifyContent: 'space-around',
    }
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const Settings = ({ partner }) => {
  const classes = useStyles();
  const [tabState, setTabState] = useState(0);

  const changeTabState = (event, newValue) => {
    setTabState(newValue);
  };
  
  return (
    <Container className={classes.container}>
      <form noValidate autoComplete="off">
        <Typography className={classes.title} variant="h4" color="inherit" >
          Manage Tags
        </Typography>
        <div className={classes.tabContainer}>
          <AppBar position="static" className={classes.tab}>
            <Tabs value={tabState} onChange={changeTabState} aria-label="simple tabs example">
              <Tab label="FORMS" />
              <Tab label="LANDING PAGES" />
              <Tab label="EMAILS" />
              <Tab label="SYSTEM EMAILS" />
            </Tabs>
          </AppBar>
          <TabPanel value={tabState} index={0}>
            <Table data={partner} type="forms" />
          </TabPanel>
          <TabPanel value={tabState} index={1}>
            <Table data={partner} type="landingPages" />
          </TabPanel>
          <TabPanel value={tabState} index={2}>
            <Table data={partner} type="emails" />
          </TabPanel>
          <TabPanel value={tabState} index={3}>
            <SlugsTable data={partner} type="systemEmails" />
          </TabPanel>
        </div>
      </form>
    </Container>
  );
};

export default Settings;
