import gql from "graphql-tag"

const CREATE_FORM_TEMPLATE = gql`
  mutation createTheme($name: String!, $position: Int, $live: Boolean, $premium: Boolean, $designFormat: String!, $closeButton: JSON, $defaultProperties: JSON, $steps: [CreateThemeStepInput], $widgetTypeId: Int, $tags: JSON) {
    createTheme(input: { name: $name, position: $position, live: $live, premium: $premium, designFormat: $designFormat, closeButton: $closeButton, defaultProperties: $defaultProperties, steps: $steps, widgetTypeId: $widgetTypeId, tags: $tags }) {
      success
      error
      theme {
        id
        name
        premium
        position
        live
        designFormat
        closeButton
        defaultProperties
        widgetTypeId
        steps {
          id,
          name,
          position,
        }
      }
    }
  }
`;

export default CREATE_FORM_TEMPLATE
