import React, { useState } from 'react'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'

const MySelect = (props) => {

  const { label, name, value, callback, options } = props
  const [input, setInput] = useState(value)

  const onChange = (e) => {
    const { value } =  e.target
    setInput(value)
    callback && callback({[name]: value})
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel >{label}</InputLabel>
      <Select
        value={input}
        onChange={onChange}
      >
        { options.map((item,index) =>
          <MenuItem key={index} value={item.name}>{item.text}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}
export default MySelect