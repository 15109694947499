import gql from "graphql-tag"

const FETCH_PARTNER = gql`
query CurrentPartner {  
    currentPartner {
      id
      name
      platformFeatures
      logo
      appDomain
      formsDomain
      pagesDomain
      cdnDomain
      labels
      emailSettings
    }
  }
`

export default FETCH_PARTNER