import gql from "graphql-tag"

const BULK_REMOVE_TAGS_FROM_LANDING_PAGE_TEMPLATES = gql`
  mutation bulkRemoveTagsFromLandingPageTemplates(
    $tags: [String]
    $templatePageIds: [Int]
  ) {
    bulkRemoveTagsFromLandingPageTemplates(input: {
      tags: $tags
      templatePageIds: $templatePageIds
    }) {
      success
      error
    }
  }
`

export default BULK_REMOVE_TAGS_FROM_LANDING_PAGE_TEMPLATES
