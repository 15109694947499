import React from 'react'
import UnlayerEditor from '../UnlayerEditor'
import update from 'immutability-helper'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Select, MenuItem, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import CardContainer from './CardContainer'
import CloseButton from './CloseButton'
import DefaultProperties from './DefaultProperties';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash'
import { UserContext } from '../../../UserContext'
import { getTagOptions } from '../../common/constants';
import { copyToClipboard } from '../../../utils';
import CodeMirrorEditor from '../../common/CodeMirrorEditor';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  main: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  reactSelect: {
    zIndex: 1
  }
})

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class Container extends React.Component {
  constructor(props) {
    super(props)

    const { template = {}, widgetTypes = [], isDuplicate, themesCount } = this.props
    let defaultTypeId = null
    if(isDuplicate) {
      defaultTypeId = template.widgetTypeId
    } else if(widgetTypes.length) {
      defaultTypeId = widgetTypes[0].id
    }
    const steps = template.steps && template.steps.sort((a, b) => {
      return a.position - b.position
    }) || [];
    this.state = {
      name: template.name || '',
      position: template.position || themesCount + 1,
      premium: template.premium || false,
      live: template.live || false,
      designFormat: template.designFormat || 'drag_drop',
      showEditor: false,
      steps,
      stepIndex: 0,
      closeButton:  template.closeButton,
      defaultProperties: template.defaultProperties,
      widgetTypeId: template.widgetTypeId || defaultTypeId,
      tags: template.tags || [],
      showCodeMirror: false,
      showAlert: false,
      alertData: {
        type: 'success', message: null
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.template) {
      const { steps } = this.props.template
      // update state when step are refetched
      if(!_.isEqual(prevProps.template.steps,steps)) {
        this.setState({ steps: steps && steps.sort((a, b) => {
          return a.position - b.position
        }) || []})
      }
    }
  }

  setSteps = (steps) => {
    this.setState({ steps })
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    })
  }

  handleChange = (newValue, actionMeta) => {
    const tags = newValue ? newValue.map(item => {
      return item.value
    }) : []
    this.setState({ tags })
  };

  isParseAble(value) {
    try{
      JSON.parse(value)
      return true
    }
    catch(error) {
      return false
    }
  }
  render() {
    const { classes, onSave, widgetTypes = null, isNew, isDuplicate } = this.props
    const { name, position, tags, premium, live, unrollTemplateId, designFormat, showEditor, steps, stepIndex, closeButton, defaultProperties, widgetTypeId, stringifyedJson, showCodeMirror, alertData, showAlert } = this.state
    const { json } = steps && steps[stepIndex] || {}
    const type = widgetTypes.find(item => item.id === widgetTypeId)
    let user = this.context
    const options = getTagOptions(user,'forms')

    return (
      <div className={classes.main}>
        <form onSubmit={async(e) => {
          e.preventDefault()
          try {
            const filteredDefaultProperties = designFormat === 'drag_drop' ? Object.keys(defaultProperties)
            .filter(key => defaultProperties.includes.includes(key))
            .reduce((obj, key) => {
              obj[key] = defaultProperties[key];
              return obj;
            }, {}): defaultProperties;
            onSave({ name, position: parseInt(position), premium, live, designFormat, closeButton: closeButton, defaultProperties: filteredDefaultProperties, steps, widgetTypeId, tags })
          }
          catch(error) {
            console.log('error',error)
          }
        }}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              name="name"
              value={name}
              onChange={(e) => { this.handleInputChange(e) }}
              autoFocus
            />
          </FormControl>
          
          {
            widgetTypes && (isNew || isDuplicate) &&
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="type">Form type</InputLabel>
              <Select
                value={widgetTypeId}
                onChange={(e) => { this.handleInputChange(e) }}
                inputProps={{
                  name: 'widgetTypeId',
                  id: 'widgetTypeId',
                }}
              >
                {
                  widgetTypes.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          }
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="position">Position</InputLabel>
            <Input
              id="position"
              name="position"
              value={position}
              onChange={(e) => { this.handleInputChange(e) }}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth classes={{ root: classes.reactSelect }}>
            <InputLabel className="MuiInputLabel-shrink" htmlFor="tags">Tags</InputLabel>
            <CreatableSelect
              isMulti
              onChange={this.handleChange}
              defaultValue={tags.map(item => ({ value: item, label: item }))}
              options={options}
              styles={{ 
                control: styles => ({ ...styles, 
                  margin: 0,
                  marginTop: 16,
                  border: 0,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                  borderRadius: 0,
                  minHeight: 32,
                  boxShadow: 'none',
                  ':hover': {
                    borderColor: 'rgba(0, 0, 0, 0.42)',
                  },
                }),
                container: styles => ({
                  fontFamily: 'sans-serif'
                }),
                indicatorsContainer: styles => ({
                  display: 'none',
                }),
                placeholder: styles => ({
                  display: 'none',
                }),
              }}
            />
          </FormControl>
          {
            designFormat === 'drag_drop' &&
            <React.Fragment>
              <FormControl style={{ marginTop: 10 }}>
                <CloseButton data={closeButton} type={type} callback={(value) => {
                    this.setState({ closeButton: value });
                  }}/>
              </FormControl>
              <FormControl style={{ marginTop: 10 }}>
                <DefaultProperties data={defaultProperties} type={type} callback={(value) => {
                    this.setState({ defaultProperties: {...defaultProperties, ...value }},
                      () => {
                        const { defaultProperties: { width, overlay_color }} = this.state
                        this.syncSteps({ contentWidth: width, backgroundColor: overlay_color })
                      }
                    )
                    
                  }}/>
              </FormControl>
            </React.Fragment>
          }
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.premium}
                  onChange={(e) => { this.handleInputChange(e) }}
                  name="premium"
                  value="premium"
                />
              }
              label="Premium"
            />
          
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.live}
                  onChange={(e) => { this.handleInputChange(e) }}
                  name="live"
                  value="live"
                />
              }
              label="Live"
            />
          </FormGroup>

          { designFormat === 'drag_drop' &&
          <React.Fragment>
            <FormGroup row>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => { this.addPage() }}
              >
                Add Page
              </Button>
            </FormGroup>

            <FormGroup row>
              <CardContainer
                onJsonMenuClicked={(id) => this.setState({ stepIndex: id })}
                onMenuItemSelect={(value) => {
                  const stringifyedJson = JSON.stringify(json, null, 2)
                  if (value === 'copy') {
                    copyToClipboard(stringifyedJson).then(() => {
                      this.setState({
                        showAlert: true,
                        alertData: {
                          type: 'success',
                          message: 'JSON Copied to Clipboard !'
                        }
                      });
                    }).catch(() => {
                      this.setState({
                        showAlert: true,
                        alertData: {
                          type: 'error',
                          message: 'Error in copying to clipboard !'
                        }
                      });
                    });
                  } else {
                    this.setState({ 
                      showCodeMirror: true, 
                      stringifyedJson
                    });
                  }
                }}
                setSteps={this.setSteps} 
                handleEditorOpen={this.handleEditorOpen} 
                handleDelete={this.handleDelete} 
                handleDuplicate={this.handleDuplicate}
                steps={steps}
              />
            </FormGroup>
          </React.Fragment>
          }
        
          <hr />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button>
        </form>
        
        <Dialog
          fullScreen
          open={this.state.showEditor}
          onClose={this.handleEditorClose}
          TransitionComponent={Transition}
        >
          <AppBar color="secondary" className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleEditorClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Editor
              </Typography>
              <Button color="inherit" onClick={this.handleEditorSave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          
          {showEditor && <UnlayerEditor
            displayMode="web"
            json={json}
            linkTypes={[
              {
                name: "go_to_step",
                label: "Go to Step",
                attrs: {
                  onClick: 'loadStep({{step}});'
                },
                fields: [
                  {
                    name: 'step',
                    label: 'Step',
                    defaultValue: stepIndex == 0 ? 1 : 0,
                    options: (steps.filter((step) => step.id !== steps[stepIndex].id).map((step) => {
                      return { value: step.position, label: step.name }
                    }))
                  }
                ]
              }
            ]}
            widgetType={type}
            templateId={unrollTemplateId}
            closeEditor={this.handleEditorClose}
          />}
        </Dialog>

        <CodeMirrorEditor
          onEditorChanged={(editor, data, value) => this.setState({ stringifyedJson: value })}
          onEditorCancel={() => this.setState({ showCodeMirror: false, stringifyedJson: JSON.stringify(json, null, 2) })}
          onSave={() => this.handleSave({ onSave })}
          stringifyedJson={stringifyedJson}
          isOpen={showCodeMirror} 
          transition={Transition}
          classes={classes}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showAlert}
          autoHideDuration={5000}
          onClose={() => this.setState({ showAlert: false })}
        >
          <Alert severity={alertData.type}>
            {alertData.message}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  
  addPage({ json: defaultJson, name, screenshotUrl, html, css } = {}) {
    const { createNew = false } = this.props
    const { width, overlay_color } = this.state.defaultProperties
    let json = '{"counters":{"u_column":1,"u_row":1},"body":{"rows":[{"cells":[1],"columns":[{"contents":[],"values":{"backgroundColor":"","padding":"0px","border":{},"_meta":{"htmlID":"u_column_1","htmlClassNames":"u_column"}}}],"values":{"columns":false,"backgroundColor":"","columnsBackgroundColor":"","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"padding":"0px","hideMobile":false,"noStackMobile":false,"_meta":{"htmlID":"u_row_1","htmlClassNames":"u_row"},"selectable":true,"draggable":true,"deletable":true}}],"values":{"backgroundColor":"#e7e7e7","backgroundImage":{"url":"","fullWidth":true,"repeat":false,"center":true,"cover":false},"contentWidth":"500px","fontFamily":{"label":"Arial","value":"arial,helvetica,sans-serif"},"linkColor":"#0000ee","linkHoverColor":"#0000ee","linkUnderline":true,"_meta":{"htmlID":"u_body","htmlClassNames":"u_body"}}}}'
    json = defaultJson || JSON.parse(json)
    json.body.values.backgroundColor = overlay_color
    json.body.values.contentWidth = `${width}px`
    let newPage = {
      name: name || `page-${this.state.steps.length+1}`,
      position: this.state.steps.length,
      json,
      screenshotUrl,
      html,
      css,
    }
    // insert id for update mutation use only
    if(!createNew) 
      newPage.id = 0
    this.setState({
      steps: [
        ...this.state.steps,
        newPage
      ]
    })
  }

  syncSteps = ({ backgroundColor, contentWidth }) => {
    this.state.steps.map((item) => {
      let json = item.json
      json.body.values.backgroundColor = backgroundColor
      json.body.values.contentWidth = `${contentWidth}px`
    })
  }

  handleEditorSave = () => {
    const { stepIndex, steps, defaultProperties } = this.state
    
    const { onSave } = this.props
    try {
      window.unlayer.exportHtml((data) => {
        const { body: { values: { backgroundColor, contentWidth: defaultContentWidth } = {}} = {}} = data.design || {}
        let contentWidth = defaultContentWidth.replace('px','')
        this.syncSteps({ contentWidth, backgroundColor })
        const newDefaultProperties = update(defaultProperties,{
          overlay_color: { $set: backgroundColor },
          width: { $set: contentWidth },
        })
        this.setState({
          json: data.design,
          defaultProperties: newDefaultProperties,
          steps: update(steps, {
            [stepIndex]: {
              json: { $set: data.design },
              css:  { $set: data.chunks.css },
              html:  { $set: data.chunks.body },
              fonts:  { $set: data.chunks.fonts }
            },
          })
        }, () => {
          const { name, position, premium, live, designFormat, closeButton, defaultProperties, steps, widgetTypeId, tags } = this.state
          onSave({ name, position: parseInt(position), premium, live, designFormat, closeButton, defaultProperties, steps, widgetTypeId, tags }, false)
          this.handleEditorClose()
        })
      })
    } catch(err) {
      console.log("ERR", err)
      this.handleEditorClose()
    }
  }

  handleEditorClose = () => {
    this.setState({
      showEditor: false,
    })
  }

  handleEditorOpen = (stepIndex) => {
    this.setState({
      showEditor: true,
      stepIndex
    })
  }

  handleDuplicate = (stepIndex) => {
    const { steps } = this.state
    const { json, name, screenshotUrl, html , css } = steps[stepIndex]
    this.addPage({ json, name: `${name}-copy`, screenshotUrl, html, css })
  }

  handleSave = ({ onSave }) => {
    const { stringifyedJson, stepIndex, steps, name, position, premium, live, designFormat, closeButton, defaultProperties, widgetTypeId, tags } = this.state;
    try {
      if (['null', '{}'].includes(stringifyedJson)) {
        throw 'JSON cannot be empty';
      }
      JSON.parse(stringifyedJson);
      const filteredSteps = steps.filter((step, index) => index !== stepIndex)
      onSave({ 
        name, 
        position: parseInt(position), 
        premium, 
        live, 
        designFormat, 
        closeButton, 
        defaultProperties, 
        steps: [
          ...filteredSteps,
          {
            ...steps[stepIndex],
            json: JSON.parse(stringifyedJson)
          }
        ], 
        widgetTypeId, 
        tags 
      }, false);
      this.setState({ 
        showCodeMirror: false, 
        showAlert: true, 
        alertData: { type: 'success', message: 'JSON updated successfully !' } 
      });
    } catch (error) {
      this.setState({
        showAlert: true,
        alertData: {
          type: 'error', message: 'Invalid JSON'
        }
      });
    }
  };

  handleDelete = (stepIndex) => {
    const { steps } = this.state
    const newSteps = update(steps, {
      $splice: [[stepIndex, 1]],
      $apply: function(steps) {
        return steps.map((step,index) => {
          return update(step, {
            position: { $set: index } 
          })
        })
      }
    });

    this.setState({
      steps: newSteps
    })
  }
}

Container.contextType = UserContext
export default withStyles(styles)(Container)
