import gql from "graphql-tag"

const DELETE_TAG = gql`
  mutation deleteTag($partnerId: Int!, $tagKey: String!, $tagName: String!) {
    deleteGlobalTagFromPartner(input: { partnerId: $partnerId, tagKey: $tagKey, tagName: $tagName }) {
      success
      error
      partner {
        id
        tags
      }
    }
  }
`;

export default DELETE_TAG