import { post } from 'axios'

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export // https://stackoverflow.com/a/31205878/673379
function appendToFilename(filename, string) {
  const dotIndex = filename.lastIndexOf(".")
  if (dotIndex === -1) return filename + string;
  else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
}

export function savePixieFileAndUpload(file, name, partner, config) {
  const { awsAccessKeyId, awsBucket } = partner.variables
  const templateId = window.templateId;
  
  const suffix = `_${Date.now()}`
  const key = `themes/custom_screenshots/${templateId}/${appendToFilename(name, suffix)}`
  const conditions = [
    {acl: "public-read"},
    {bucket: `${awsBucket}`},
    {"Content-Type": "image/"},
    {success_action_status: "200"},
    {key: key},
    {"x-amz-meta-qqfilename": name},
    ["content-length-range", "0", "50000000"],
  ]
  return post(`//${partner.appDomain}/uploads/signature`, { conditions }, config)
  .then(function (response) {
    const { data, status } = response
    const { policy, signature } = data
    
    const url = `https://${awsBucket}.s3.amazonaws.com`
    if(status === 200) {
      let ImageURL = file
      // Split the base64 string in data and contentType
      let block = ImageURL.split(";");
      // Get the content type
      let contentType = block[0].split(":")[1];// In this case "image/gif"
      // get the real base64 content of the file
      let realData = block[1] ? block[1].split(",")[1] : '';// In this case "iVBORw0KGg...."
      // Convert to blob
      var blob = b64toBlob(realData, contentType);
      
      const formData = new FormData();
      formData.set('key', key)
      formData.set('AWSAccessKeyId',`${awsAccessKeyId}`)
      formData.set('Content-Type', 'image/png');
      formData.set('success_action_status', '200');
      formData.set('acl', 'public-read');
      formData.set('x-amz-meta-qqfilename', name);
      formData.set('policy',policy)
      formData.set('signature',signature)
      formData.set("file", blob, name)
      
      return post(url, formData, config).then(function (uploadResponse) {
        return {
          url,
          key
        }
      });
    }
  })
  .catch(function (error) {
    console.log('error', error)
  });
}
