import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDebounce } from '../../utils'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateModal from './modals/CreateModal';
import DeleteModal from './modals/DeleteModal';
import IS_SLUG_AVAILABLE from '../../graphql/query/isSlugAvailable';
import DELETE_SLUG from '../../graphql/mutation/deleteSlug';
import ADD_SLUG from '../../graphql/mutation/addSlug';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 25,
    color: 'rgb(225, 0, 80)',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  slugContainer: {
    backgroundColor: 'rgb(230, 230, 230)',
    borderRadius: 5,
    width: 'fit-content'
  },
  slug: {
    color: 'rgb(51, 51, 51)',
    padding: 5,
    whiteSpace: 'nowrap',
    borderRadius: 5,
    fontSize: 13,
  },
  button: {
    float: 'right',
    marginBottom: 20,
    padding: '7px 15px',
  }
}));

const SlugTable = ({ data, type }) => {
  const classes = useStyles();
  const { id, meta: { slugs } } = data;
  const [addSlug] = useMutation(ADD_SLUG);
  const [deleteSlug,{ data: { deleteGlobalSlugFromPartner: { error, success } = {} } = {} }] = useMutation(DELETE_SLUG);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [activeSlug, setActiveSlug] = useState('');
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const debouncedSlug = useDebounce(activeSlug,300)

  const { loading } = useQuery(IS_SLUG_AVAILABLE, {
    fetchPolicy: "network-only",
    variables: {
      slugKey: type,
      slugName: debouncedSlug
    },
    onCompleted: data => {
      const { isPartnerGlobalSlugNameAvailable = true } = data || {};
      setErrorDuplicate(!isPartnerGlobalSlugNameAvailable);
    }
  });

  let rows = [];
  slugs[type].forEach(slug => {
    rows.push({
      slug,
      button: <DeleteIcon className={classes.icon} onClick={() => handleClickOpen(slug)}/>
    });
  })

  const handleClickOpen = slug => {
    setActiveSlug(slug);
    setOpenDelete(true);
  };
  
  const handleChange = slug => {
    setErrorEmpty(!slug);
    setActiveSlug(slug.replace(/[^a-zA-Z0-9-_]/g, ''));
  };

  const handleClose = () => {
    setErrorEmpty(false);
    setErrorDuplicate(false);
    setActiveSlug('');
    setOpenCreate(false);
  };

  const mutationWrapper = async (fn, callback) => {
    try {
      await fn({
        variables: {
          partnerId: id,
          slugKey: type,
          slugName: activeSlug,
        }
      })
      callback();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreate = event => {
    event.preventDefault();
    if(!activeSlug) {
      setErrorEmpty(true);
      return;
    }
    mutationWrapper(addSlug, () => setOpenCreate(false));
  }

  const handleDelete = event => {
    event.preventDefault();
    mutationWrapper(deleteSlug, () => {
      if(!success) {
        return setErrorEmpty(true)
      }
      setOpenDelete(false)
    });
  }

  return (
    <>
    {
    openCreate 
    && 
    <CreateModal 
      name="Slug"
      open={openCreate} 
      loading={loading} 
      value={activeSlug}
      errorDuplicate={errorDuplicate} 
      errorEmpty={errorEmpty} 
      handleClose={handleClose} 
      onChange={handleChange} 
      createTag={handleCreate}
      />
     }
    {
    openDelete 
    && 
    <DeleteModal 
      name="Slug"
      open={openDelete} 
      deleteTag={handleDelete} 
      error={errorEmpty} 
      handleClose={() => {
        if(errorEmpty) setErrorEmpty(false)
        setActiveSlug('')
        setOpenDelete(false)
      }}
      />
    }
    <Button
      className={classes.button}
      size="large"
      variant="contained"
      color="primary"
      onClick={() => setOpenCreate(true)}
    >
    + New Slug
    </Button>
    <TableContainer component={Paper}>
      <MaterialTable className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Slug</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => 
            <TableRow key={row.slug+index}>
              <TableCell component="th" scope="row">
                <div className={classes.slugContainer}>
                  <div className={classes.slug}>{row.slug}</div>
                </div>
              </TableCell>
              <TableCell align="right">{row.button}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>
    </TableContainer>
    </>
  );
};

export default SlugTable;