import React from "react"
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Snack = ({ state, error, handleClose }) => {

  return(
    <div>
      <Snackbar
        open={state}
        onClose={handleClose}
        autoHideDuration={5000}
      >
        <Alert severity="error" onClose={handleClose}>
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Snack