import React from 'react'
import Card from './Card'
import update from 'immutability-helper'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  cardContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  card: {
    width: 400,
  }
})

const Container = ({ onMenuItemSelect, onJsonMenuClicked, handleEditorOpen, handleDelete, handleDuplicate, setSteps, steps, classes }) => {
  

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = steps[dragIndex]
    const updatesSteps =  update(steps, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      $apply: function(steps) {
        return steps.map((step,index) => {
          return update(step, {
            position: { $set: index } 
          })
        })
      }
    })
    setSteps(
      updatesSteps
    )
  }

  const updateCard = (index,key,value) => {
    setSteps(
      update(steps, {
        [index]: { [key]: { $set: value }},
      })
    )
  }
  
  return (
    <Grid container direction="row" spacing={2} className={classes.cardContainer}>  
      {steps.map((card, i) => (
        <Card
          key={i}
          index={i}
          id={card.id}
          card={card}
          moveCard={moveCard}
          handleEditorOpen={() => handleEditorOpen(i)}
          handleDelete={() => handleDelete(i)}
          handleDuplicate={() => handleDuplicate(i)}
          updateCard={(key,value) => updateCard(i,key,value)}
          onJsonMenuClicked={() => onJsonMenuClicked(i)}
          onMenuItemSelect={onMenuItemSelect}
        />
      ))}
    </Grid>
  )
}
export default withStyles(styles)(Container)
