import React, { Component } from 'react'

import ColorPicker from 'rc-color-picker'

import styled from 'styled-components'

const ColorPickerContainer = styled.div`
  .rc-color-picker-trigger {
    width: 30px;
    height: 30px;
    border: 1px solid #CCC;
  }
`

const Label = styled.label`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.00938em;
`

class Container extends Component {
  constructor(props) {
    super(props)
    
    const { value } = this.props

    this.state = {
      value: value.toUpperCase(),
      show: false
    }
  }
  
  componentDidUpdate(prevProps) {
    if(this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value.toUpperCase()
      })
    }
  }

  render() {
    const { value, label, update } = this.props
    
    return (
      <div className="color-picker" style={{"marginTop": 10}}>
        <Label>{label}</Label>
        
        <ColorPickerContainer>
          <ColorPicker
            color={this.getColor()}
            placement="topLeft"
            alpha={this.getAlpha()}
            onChange={this.handleChange}
            onClose={this.handleClose}
          />
        </ColorPickerContainer>
      </div>
    )
  }
  
  componentToHex = (c) => {
    const hex = c.toString(16)
    return hex.length == 1 ? "0" + hex : hex
  }

  rgbToHex = (r, g, b) => {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
  }

  hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  }

  handleClick = () => {
    this.setState({ show: !this.state.show })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  handleChange = (color) => {
    const { name, update } = this.props
    const hex = color.color
    const rgb = this.hexToRgb(hex)
    const alpha = color.alpha
    const rgba = "rgba("+rgb.r+","+rgb.g+","+rgb.b+","+ alpha/100 +")"
    const value = (alpha < 100) ? rgba : hex
    this.setState({ value })
    update(name, value)
  }

  getColor = () => {
    let color = this.state.value.toLowerCase()
    if (color.indexOf("rgba") > -1) {
      let rgba = color.replace("rgba(", "").replace(")", "").split(",")
      color = this.rgbToHex(parseFloat(rgba[0]), parseFloat(rgba[1]), parseFloat(rgba[2]))
    }  
    return color
  }

  getAlpha = () => {
    let alpha = 100
    let color = this.state.value.toLowerCase()
    if (color.indexOf("rgba") > -1) {
      let rgba = color.replace("rgba(", "").replace(")", "").split(",")
      alpha = parseFloat(rgba[3]) * 100
    }
    return alpha
  }
}

export default Container
