import gql from "graphql-tag"

const BULK_REMOVE_TAGS_FROM_THEME = gql`
  mutation bulkRemoveTagsFromTheme(
    $tags: [String]
    $themeIds: [Int]
  ) {
    bulkRemoveTagsFromTheme(input: {
      tags: $tags
      themeIds: $themeIds
    }) {
      success
      error
    }
  }
`

export default BULK_REMOVE_TAGS_FROM_THEME
