import React from 'react';
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components';
import { Controlled as CodeMirror } from 'react-codemirror2'

import 'codemirror/lib/codemirror.css';
import "codemirror/mode/javascript/javascript.js";
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/display/autorefresh';

const CodeMirrorWrapper = styled.div`
  height: 100%;
  .CodeMirror { height: 100%; }
  .react-codemirror2 { 
    height: 100%; 
    border: 2px solid #EFF0F6;
    border-radius: 4px;
  }
`

class CodeMirrorEditor extends React.Component {
  render() {
    const {
      isOpen,
      transition,
      onEditorCancel,
      classes,
      onSave,
      onEditorChanged,
      stringifyedJson
    } = this.props;
    return (
      <Dialog
        fullScreen
        open={isOpen}
        TransitionComponent={transition}
      >
        <AppBar color="secondary" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              JSON Editor
            </Typography>
            <Button
              color="inherit"
              onClick={onSave}>
              save
            </Button>
            <Button
              color="inherit"
              onClick={onEditorCancel}>
              cancel
            </Button>
          </Toolbar>
        </AppBar>

        <div style={{ marginTop: '20px', padding: '0px 36px 0px 36px', height: 'calc(100% - 68px)' }}>
          <Alert style={{ display: 'flex', justifyContent: 'center' }} severity="error">
            WARNING! Making changes here might permanently break your template. Please be careful.
          </Alert>
          <div style={{ marginTop: '20px', height: 'calc(100% - 100px)' }}>
            <CodeMirrorWrapper>
              <CodeMirror
                value={stringifyedJson}
                onBeforeChange={onEditorChanged}
                options={{
                  mode: {
                    name: "javascript",
                    json: true
                  },
                  placeholder: '<!-- PASTE YOUR JSON HERE -->',
                  lineNumbers: true,
                  matchBrackets: true,
                  lineNumbers: true,
                  showCursorWhenSelecting: false
                }}
              />
            </CodeMirrorWrapper>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default CodeMirrorEditor;
