import gql from "graphql-tag"

const UPDATE_SYSTEM_EMAIL_TEMPLATE = gql`
  mutation UpdateSystemEmailTemplate($id: Int!, $name: String, $position: Int, $json: JSON, $tags: JSON) {
    updateSystemEmailTemplate(input: { id: $id, name: $name, position: $position, json: $json, tags: $tags }) {
      success
      systemEmailTemplate {
        id
        name
        position
        json
        tags
      }
    }
  }
`

export default UPDATE_SYSTEM_EMAIL_TEMPLATE