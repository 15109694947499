import gql from "graphql-tag"

const BULK_ASSIGN_TAGS_TO_LANDING_PAGE_TEMPLATES = gql`
  mutation bulkAssignTagsToLandingPageTemplates(
    $tags: [String]
    $templatePageIds: [Int]
  ) {
    bulkAssignTagsToLandingPageTemplates(input: {
      tags: $tags
      templatePageIds: $templatePageIds
    }) {
      success
      error
    }
  }
`

export default BULK_ASSIGN_TAGS_TO_LANDING_PAGE_TEMPLATES
