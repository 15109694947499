import React from "react"
import gql from "graphql-tag"
import { Mutation } from "react-apollo"

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import withStyles from '@material-ui/core/styles/withStyles'

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(input: {email: $email, password: $password}) {
      token
      user {
        isPartnerAdmin
      }
    }
  }
`;

const styles = theme => ({
  main: {
    width: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { classes, setToken } = this.props
    
    return (
      <Mutation mutation={SIGN_IN}>
      {(signIn, { error, loading, data }) => (
        <div className={classes.main}>
          {loading && <p>Loading...</p>}
          {error && <p>{error.message}</p>}
          <form onSubmit={async (e) => {
            e.preventDefault()
            try {
              const response = await signIn({
                variables: {
                  email: this.state.email,
                  password: this.state.password
                }
              })
              if (response.data.signIn.token) {
                if (!response.data.signIn.user.isPartnerAdmin) {
                  alert("You do not have access to this portal.")
                  return
                }
                setToken(response.data.signIn.token)
                window.location.reload()
              }
            }
            catch(e) {
            }
          }}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                value={this.state.password}
                onChange={this.handleInputChange}
                autoComplete="current-password" />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>
          </form>
        </div>
      )}
      </Mutation>
    )
  }
}

export default withStyles(styles)(Login)
