import React, { Component } from 'react'
import styled from 'styled-components'
import { Grid, Paper, Select, MenuItem, TextField, FormControl, InputLabel } from '@material-ui/core'
import ColorPicker from './ColorPicker'

const Label = styled.label`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.00938em;
`

class Editor extends Component {
  componentWillMount() {
    const {
      padding = {},
      margin = {},
      size = "18",
      position = 'top-right',
      bgColor = 'rgba(255,0,0,0)',
      color = '#000000',
      bgColorHover = 'rgba(255,0,0,0)',
      colorHover = '#000000',
      shape = 'circle',
      weight = 'light'
    } = this.props.data || {}

    let initPadding = {}
    let initMargin = {}
    
    {
       const { top = 0, left = 0, bottom = 0, right = 0 } = padding
       initPadding =  { top, left, bottom, right }
    }
    
    {
      const { top = 0, left = 0, bottom = 0, right = 0 } = margin
      initMargin = { top, left, bottom, right }
    }

    this.setState({
      padding: initPadding,
      margin: initMargin, 
      size, 
      position, 
      bgColor, 
      color, 
      bgColorHover, 
      colorHover, 
      shape,
      weight 
    },() => this.update())
  }
  
  onChange = (e,object,key) => {
    let newState = {...this.state}
    newState[object][key] = e.target.value
    this.setState(newState,() => this.update())
  }

  valueChange = (object,value) => {
    let newState = {...this.state}
    newState[object] = value
    this.setState(newState,() => this.update())
  }

  render() {
    const { padding, margin, bgColor, bgColorHover, size, position, color, colorHover, shape, weight } = this.state
    const paddingFields = Object.keys(padding).map((key,index) => {
      return (
        <Grid item key={index} xs={6}>
          <TextField
            label={key}
            type="number"
            step="1"
            min={0}
            max={100}
            value={padding[key]}
            onChange={(e) => this.onChange(e,'padding',key)}
            margin="normal"
            variant="outlined"
          />
        </Grid>
      )
    })

    const marginFields = Object.keys(margin).map((key,index) => {
      return (
        <Grid item key={index} xs={6}>
          <TextField
            label={key}
            type="number"
            step="1"
            min={0}
            max={100}
            value={margin[key]}
            onChange={(e) => this.onChange(e,'margin',key)}
            margin="normal"
            variant="outlined"
          />
        </Grid>
      )
    })

    const { type } = this.props
    const invalidType = ['Sidebar', 'Embedded']
    return (
      <React.Fragment>
        { 
          !invalidType.includes(type.name) &&
          <React.Fragment>
            <InputLabel style={{ transform: 'translate(0, 1.5px) scale(0.75)'}} className="mt-10" htmlFor="closeButton">CloseButton Properties</InputLabel>
            <hr />
            <Paper style={{ padding: 16 }}>
            <Grid container spacing={4}>
              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <Label>Regular</Label> <br />
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <ColorPicker name={'bgColor'} value={bgColor} label={'Background'} update={this.valueChange} />
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker name={'color'} value={color} label={'Icon'} update={this.valueChange} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Label>Hover</Label>
                  <Grid container spacing={4}>
                    <Grid item>
                      <ColorPicker name={'bgColorHover'} value={bgColorHover} label={'Background'} update={this.valueChange} />
                    </Grid>
                    <Grid item>
                      <ColorPicker name={'colorHover'} value={colorHover} label={'Icon'} update={this.valueChange} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item container spacing={4} className="mt-3">
                <Grid item xs={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <FormControl style={{width: '100%'}}>
                        <InputLabel >Position</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={position}
                          onChange={(e) => {this.valueChange('position',e.target.value)}}
                        >
                          <MenuItem value={'top-right'}>Top Right</MenuItem>
                          <MenuItem value={'top-left'}>Top Left</MenuItem>
                          <MenuItem value={'bottom-right'}>Bottom Right</MenuItem>
                          <MenuItem value={'bottom-left'}>Bottom Left</MenuItem>
                        </Select>
                    </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <FormControl style={{width: '100%'}}>
                        <InputLabel >Shape</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={shape}
                          onChange={(e) => {this.valueChange('shape',e.target.value)}}
                        >
                          <MenuItem value={'circle'}>Circle</MenuItem>
                          <MenuItem value={'square'}>Square</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item container spacing={4}>
                <Grid item xs={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <FormControl style={{width: '100%'}}>
                        <InputLabel >Weight</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={weight}
                          onChange={(e) => {this.valueChange('weight', e.target.value)}}
                        >
                          <MenuItem value={'light'}>Light</MenuItem>
                          <MenuItem value={'regular'}>Regular</MenuItem>
                          <MenuItem value={'solid'}>Solid</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                     <FormControl style={{width: '100%'}}>
                        <InputLabel >Size</InputLabel>
                        <Select
                          style={{ width: '100%' }}
                          value={size}
                          onChange={(e) => {this.valueChange('size', e.target.value)}}
                        >
                          <MenuItem value={'18'}>Small</MenuItem>
                          <MenuItem value={'22'}>Medium</MenuItem>
                          <MenuItem value={'26'}>Large</MenuItem>
                          <MenuItem value={'33'}>XL</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item container spacing={4}>
                <Grid item>
                  <Label>Padding</Label>
                  <Grid container spacing={2}>
                    {paddingFields}
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item container spacing={4}>
                <Grid item>
                  <Label>Margin</Label>
                  <Grid container spacing={2}>
                  { marginFields}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            </Paper>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
  
  update = () => {
    const { callback } = this.props
    callback(this.state)
  }
}

export default Editor
