import React, { useState } from 'react'
import Checkbox from './checkbox'
import { Button, ClickAwayListener } from '@material-ui/core'

const CheckBoxWithConfirm = (props) => {
  const { callback, ...rest } = props
  const [displayConfirmation, setDisplayConfirmation] = useState(false)
  const [ret, setRet] = useState(null)

  const handleConfirm = (props) => {
    callback && callback(ret)
    setDisplayConfirmation(false)
  }

  const handleChange = (props) => {
    setDisplayConfirmation(true)
    setRet(props)
  }
  
  return ( 
    <React.Fragment>
    {
       displayConfirmation ? 
        <ClickAwayListener onClickAway={() => setDisplayConfirmation(false)}>
          <Button size="small" variant="contained" color="primary" fullWidth onClick={handleConfirm}>Confirm</Button>
        </ClickAwayListener>
       : <Checkbox {...rest} callback={handleChange} useNumeric={false} /> 
    }
    </React.Fragment>
  )
}

export default CheckBoxWithConfirm