import gql from "graphql-tag"

const FETCH_EMAIL_TEMPLATE = gql`
query EmailTemplate($id: Int!) {  
  emailTemplate(id: $id) {
    id
    name
    premium
    position
    live
    screenshotUrl
    unrollTemplateId
    designFormat
    json
    tags
    slug
    webflowSettings
  }
}
`

export default FETCH_EMAIL_TEMPLATE