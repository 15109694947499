import React from 'react'
import { Redirect } from "react-router-dom"

import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"

import FETCH_LANDING_PAGE_TEMPLATE from '../../../graphql/query/fetchLandingPageTemplate'
import Editor from './Editor'

const UPDATE_LANDING_PAGE_TEMPLATE = gql`
  mutation UpdateLandingPageTemplate($id: Int!, $name: String, $position: Int, $live: Boolean, $premium: Boolean, $pages: [UpdatePageInput!], $tags: JSON ) {
    updateLandingPageTemplate(input: { id: $id, name: $name, position: $position, live: $live, premium: $premium, pages: $pages, tags: $tags}) {
      success
      landingPageTemplate {
        id
        name
        premium
        position
        live
        tags
        pages {
          id
          position
          unlayerId
          json
          html
          css
          fonts
          screenshotUrl
        }
      }
    }
  }
`

export default class EditTemplate extends React.Component {
  render() {
    const { match: { params }, history } = this.props
    const templateId = parseInt(params.id)
    return (
      <Query query={FETCH_LANDING_PAGE_TEMPLATE} variables={{ id: templateId }} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { landingPageTemplate: template } = data

          return (
            <Mutation mutation={UPDATE_LANDING_PAGE_TEMPLATE}>
            {(updateLandingPageTemplate, { error, loading, data }) => {
              //if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              // if (data && data.updateLandingPageTemplate && data.updateLandingPageTemplate.success) {
              //   return <Redirect to="/landing_pages" />
              // }

              return (
                <div>
                  <Editor template={template} onSave={(data,doRedirect=true) => {
                    const pages = data.pages.map((item) => {
                      const cpyItem = { ...item }
                      delete cpyItem.__typename
                      delete cpyItem.screenshotUrl
                      return cpyItem
                    })
                    
                    updateLandingPageTemplate({
                      variables: {
                        id: templateId,
                        name: data.name,
                        position: data.position,
                        premium: data.premium,
                        live: data.live,
                        pages,
                        tags: data.tags,
                      },
                      refetchQueries: [
                        'getCurrentUser'
                      ]
                    }).then(({data}) => {
                      if (data && data.updateLandingPageTemplate && data.updateLandingPageTemplate.success) {
                        refetch()
                        doRedirect && history.push('/landing_pages')
                      }
                    })
                  }} />
                </div>
              )
            }}
            </Mutation>
          );
        }}
      </Query>
    )
  }
}
