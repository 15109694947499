
import { Link } from "react-router-dom"

const actions = [
  { label: 'New System Email', component: Link, to: `/system_email_templates/new` }
]

const filterBy = [
  {
    name: 'name', label: 'Template name', type: 'textfield'
  },
  { name: 'tags', label: 'Tags', type: 'reactselect' },
]

const sortBy = [
  {
    label: 'Sort By', type: 'select', 
    options: [
      { value: 'POSITION', label: 'Position' },
      { value: 'NAME', label: 'Name' },
      { value: 'RECENT', label: 'Most Recent'}
    ]
  }
]

export { 
  actions, sortBy, filterBy
}