import React, { useState, useRef, useEffect } from 'react'
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'

const MySelectField = (props) => {
  const { name, label, value: defaultValue = '', onChange, options, disabled = false } = props
  const [value, setValue] = useState(defaultValue) 
  const inputLabel = useRef(null)

  useEffect(()=> {
    setValue(defaultValue)
  },[defaultValue])

  return (
    <FormControl style={{ minWidth: 120 }}>
      <InputLabel ref={inputLabel}>
        { label }
      </InputLabel>
      <Select
        disabled={disabled}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChange({ name, value: e.target.value })
        }}
      >
        {
          options.map(({value, label, name}, index) => {
            return (
              <MenuItem key={index} value={value} name={name}>{label}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default MySelectField