import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDebounce } from '../../utils'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MaterialTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';

import CreateModal from './modals/CreateModal';
import DeleteModal from './modals/DeleteModal';

import IS_TAG_AVAILABLE from '../../graphql/query/isTagAvailable';
import DELETE_TAG from '../../graphql/mutation/deleteTag';
import ADD_TAG from '../../graphql/mutation/addTag';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 25,
    color: 'rgb(225, 0, 80)',
    '&:hover': {
      cursor: 'pointer',
    }
  },
  tagContainer: {
    backgroundColor: 'rgb(230, 230, 230)',
    borderRadius: 5,
    width: 'fit-content'
  },
  tag: {
    color: 'rgb(51, 51, 51)',
    padding: 5,
    whiteSpace: 'nowrap',
    borderRadius: 5,
    fontSize: 13,
  },
  button: {
    float: 'right',
    marginBottom: 20,
    padding: '7px 15px',
  }
}));

const Table = ({ data, type }) => {
  const classes = useStyles();
  const { id, tags, meta: { slugs } } = data;
  const [addTag] = useMutation(ADD_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [activeTag, setActiveTag] = useState('');
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const debouncedTag = useDebounce(activeTag,300)

  const { loading } = useQuery(IS_TAG_AVAILABLE, {
    fetchPolicy: "network-only",
    variables: {
      tagKey: type,
      tagName: debouncedTag
    },
    onCompleted: data => {
      const { isPartnerGlobalTagNameAvailable = true } = data || {};
      setErrorDuplicate(!isPartnerGlobalTagNameAvailable);
    }
  });

  let rows = [];
  tags[type].forEach(tag => {
    rows.push({
      tag,
      button: <DeleteIcon className={classes.icon} onClick={() => handleClickOpen(tag)}/>
    });
  })

  const handleClickOpen = tag => {
    setActiveTag(tag);
    setOpenDelete(true);
  };
  
  const handleChange = tag => {
    setErrorEmpty(!tag);
    setActiveTag(tag.replace(/[^a-zA-Z0-9-_]/g, ''));
  };

  const handleClose = () => {
    setErrorEmpty(false);
    setErrorDuplicate(false);
    setActiveTag('');
    setOpenCreate(false);
  };

  const mutationWrapper = async (fn, callback) => {
    try {
      await fn({
        variables: {
          partnerId: id,
          tagKey: type,
          tagName: activeTag,
        }
      })
      callback();
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreate = event => {
    event.preventDefault();
    if(!activeTag) {
      setErrorEmpty(true);
      return;
    }
    mutationWrapper(addTag, () => setOpenCreate(false));
  }

  const handleDelete = event => {
    event.preventDefault();
    mutationWrapper(deleteTag, () => setOpenDelete(false));
  }

  return (
    <>
    {openCreate && <CreateModal name="Tag" open={openCreate} loading={loading} errorDuplicate={errorDuplicate} errorEmpty={errorEmpty} handleClose={handleClose} value={activeTag} onChange={handleChange} createTag={handleCreate} />}
    {openDelete 
      && 
      <DeleteModal
        name="Tag"
        open={openDelete}
        deleteTag={handleDelete} 
        handleClose={() => {
        if(errorEmpty) setErrorEmpty(false)
          setActiveTag('')
          setOpenDelete(false)
        }}
        />}
    <Button
      className={classes.button}
      size="large"
      variant="contained"
      color="primary"
      onClick={() => setOpenCreate(true)}
    >
    + New Tag
    </Button>
    <TableContainer component={Paper}>
      <MaterialTable className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tag</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => 
            <TableRow key={row.tag+index}>
              <TableCell component="th" scope="row">
                <div className={classes.tagContainer}>
                  <div className={classes.tag}>{row.tag}</div>
                </div>
              </TableCell>
              <TableCell align="right">{row.button}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </MaterialTable>
    </TableContainer>
    </>
  );
};

export default Table;