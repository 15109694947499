import React from 'react'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& > div > div': {
      width: 550,
    }
  },
  loader: {
    display: 'flex',
    marginBottom: 10,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

const CreateModal = ({ name, open, loading, errorDuplicate, errorEmpty, handleClose, onChange, createTag, value }) => {
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" className={classes.dialog} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Create New {name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter {name.toLowerCase()} label.
          </DialogContentText>
          <TextField
            error={errorEmpty || errorDuplicate}
            helperText={(errorEmpty && `${name} name cannot be blank.`) || (errorDuplicate && `This ${name.toLowerCase()} name is already taken.`)}
            autoFocus
            margin="dense"
            id="name"
            label={`${name} Name`}
            type="text"
            value={value}
            onChange={event => onChange(event.target.value)}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                {loading && (<div className={classes.loader}>
                  <CircularProgress size="1rem" color="secondary" />
                </div>)}
              </InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={errorEmpty || errorDuplicate || loading} onClick={createTag} color="primary">
            Submit
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
    </Dialog>
  )
}

export default CreateModal;