import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { parseLabel } from '../../utils';

const useStyles = makeStyles(theme => ({
  formgroup: {
    display: 'block',
    '& > label > span': {
      textTransform: 'capitalize',
    }
  },
  expandIcon: { 
    transform: 'none !important',
  },
  expanansionPanelSummary: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  columns: {
    columnCount: 3,
    backgroundColor: '#35353505',
    width: '100%',
    // border: '1px solid #35353545',
    borderRadius: 5,
  }
}));

const FeatureSwitch = ({ label, featureDetails, handleFeatureChange, handleDetailsChange }) => {
  const classes = useStyles();
  const { enabled, features = {} } = featureDetails
  
  return (
    <div style={{"padding": "15px 8px"}}>
      <ExpansionPanel defaultExpanded={enabled}>
        <ExpansionPanelSummary
          classes={{ expandIcon: classes.expandIcon }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.expanansionPanelSummary}>
          <Typography variant="overline" display="block" gutterBottom>
            {parseLabel(label)}
          </Typography>
          <Switch checked={enabled} onClick={handleFeatureChange(label)} color="primary" />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <div className={classes.columns}>
          {
            Object.keys(features).map((value, index) => {
              const checked = features[value].enabled
              return (
                <FormGroup className={classes.formgroup} key={`${value}-${index}`}>
                  <FormControlLabel
                    style={{ margin: 8 }}
                    control={<Switch disabled={!enabled} checked={checked} onChange={handleDetailsChange(label, value)} />}
                    label={parseLabel(value)}
                  />
                </FormGroup>
              )
            })
          }
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default FeatureSwitch;
