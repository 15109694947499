import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';

import { parseLabel } from '../../utils';

const useStyles = makeStyles(theme => ({
  emailSettingContainer: {
    padding: '15px 8px',
  },
  textfield: {
    '& > label': {
      textTransform: 'capitalize'
    }
  },
  formControl: {
    '& > span:nth-child(2)': {
      textTransform: 'capitalize'
    }
  }
}));

const EmailSettings = ({ name, error, value, handleBlur, handleChange }) => {
  const classes = useStyles();
  let type = 'text';
  if(name === 'password') {
    type = 'password';
  } else if(name === 'port') {
    type = 'number';
  }

  return (
    <div className={classes.emailSettingContainer}>
      {
        name === 'enableStarttlsAuto' ? (
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                className={classes.formControl}
                control={<Switch name={name} onChange={handleChange} checked={value} color="primary" />}
                label={parseLabel(name.split(/(?=[A-Z])/).join(" "))}
                labelPlacement="top"
              />
            </FormGroup>
          </FormControl>
        ) : (
          <TextField
            className={classes.textfield}
            label={parseLabel(name.split(/(?=[A-Z])/).join(" "))}
            name={name}
            type={type}
            value={value}
            error={error}
            helperText={error && "Incorrect entry."}
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ width: 350 }}
            placeholder={`Enter ${name}...`}
            margin="normal"
            autoComplete="new-password"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        )
      }
    </div>
  );
};

export default EmailSettings;
