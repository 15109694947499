import React from 'react'
import {
  Menu,
  MenuItem
} from '@material-ui/core'
import styled from 'styled-components';

const StyledMenu = styled((props) => (
  <Menu
    autoFocus={false}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
    }
  },
}));

const Minidropdown = ({ onMenuItemClick, onMenuClose, anchorPoint, options }) => {
  return (
    <StyledMenu
      anchorEl={anchorPoint}
      open={anchorPoint ? true : false}
      onClose={onMenuClose}
      transformOrigin={{ horizontal: 'right', vertical: -25 }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
    >
      {
        options.map(({ label, value }, index) => (
          <MenuItem
            onClick={() => onMenuItemClick(value)}
            key={index}
            style={{ fontSize: '15px' }}
          >
            {label}
          </MenuItem>
        ))
      }
    </StyledMenu>
  );
};

export default Minidropdown;