import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'

const SortByField = (props) => {
  const { name, text, value: defaultValue, onChange } = props
  const [value,setValue] = useState(defaultValue)

  const onHandleClick = () => {
    if(value === 'asc') {
      setValue('desc')
      onChange({ name, value: 'desc'})
    }
    else {
      setValue('asc')
      onChange({ name, value: 'asc'})
    }
  }

  return (
    <Button onClick={onHandleClick}>
      <Typography>{text}</Typography>
      {
        value === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>
      }
    </Button>
  )
}

export default SortByField