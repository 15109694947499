import React, { useState } from 'react'

import { useQuery, useMutation } from '@apollo/react-hooks';

import Editor from './Editor'

import FETCH_THEME from '../../../graphql/query/fetchTheme'
import CREATE_FORM_TEMPLATE from '../../../graphql/mutation/createTheme'
import FETCH_THEMES_COUNT from '../../../graphql/query/fetchThemesCount'

const DuplicateTemplate = ({widgetTypes, history, ...props}) => {
  const [template, setTemplate] = useState(undefined);
  const [createTheme] = useMutation(CREATE_FORM_TEMPLATE);
  const { match: { params } } = props;
  const templateId = parseInt(params.id);
   const { loading: countLoading, data } = useQuery(FETCH_THEMES_COUNT, { fetchPolicy: 'network-only' })
  const { loading, error } = useQuery(FETCH_THEME, {
    variables: { id: templateId },
    onCompleted: data => {
      delete data.theme.id
      const steps = data.theme.steps.map((item) => {
        const cpyItem = {...item}
        delete cpyItem.__typename
        delete cpyItem.id
        return cpyItem
      })
      const newTemplate = {
        ...data.theme,
        position: 0,
        live: false,
        steps
      }
      setTemplate(newTemplate)
    }
  });
  if (loading || countLoading || template===undefined) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const { totalThemesCount: themesCount } = data

  return (
    <Editor createNew={true} widgetTypes={widgetTypes} themesCount={themesCount} isDuplicate={true} onSave={(data,doRedirect=true) => {
      const steps = data.steps.map((item) => {
        const cpyItem = {...item}
        delete cpyItem.__typename
        delete cpyItem.screenshotUrl 
        return cpyItem
      })
      createTheme({
        variables: { ...data, steps }
      }).then(({data}) => {
        if (data && data.createTheme && data.createTheme.success) {
          const { id } = data.createTheme.theme
          if(doRedirect) {
            history.push('/form_templates')
          }
          else
            history.push(`/form_templates/${id}/edit`)
        }
      })
    }}
    template={template} />
  )  
}

export default DuplicateTemplate
