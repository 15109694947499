import React, { useState, useCallback, useEffect } from 'react'
import TemplateCard from '../../common/DraggableCard'
import update from 'immutability-helper'
import { Grid, CircularProgress } from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag"
import { Mutation } from "react-apollo"
import FilterBar from '../../common/filterBar'
import RepositionBar from '../../common/repositionBar'
import { actions, filterBy, sortBy } from './FilterConfig'
import UPDATE_SYSTEM_EMAIL_TEMPLATE from '../../../graphql/query/updateSystemEmailTemplate'

const DELETE_SYSTEM_EMAIL_TEMPLATE = gql`
  mutation DeleteSystemEmailTemplate($id: Int!) {
    deleteSystemEmailTemplate(input: { id: $id }) {
      success
    }
  }
`;

const SystemEmailTemplates = (props) => {
  const { refetchTemplates, callback, hasFilters, sorts, filters } = props
  const [state,setState] = useState({
    isReposition: false,
    maxWidth: 350,
    templates: props.templates
  })
  const [loading, setLoading] = useState(false)
  const { isReposition, templates } = state
  const [updateSystemEmailTemplate] = useMutation(UPDATE_SYSTEM_EMAIL_TEMPLATE);

  useEffect(() => {
    setState({ ...state, templates: props.templates })
  },[props.templates])

  const resetFilter = (reposition = false) => {
    callback(null, {}, true, reposition)
  }

  const updateState = (values, doUpdate = false, doRefetch = false) => {
    if(values.isReposition !== undefined)
    values.isReposition === true ? resetFilter(values.isReposition) : resetFilter()
    setState({ ...state, ...values, ...(doRefetch && { templates: props.templates })}) 
    if(doUpdate) {
      setLoading(true)
      Promise.all(
        templates.map(async ({id, position},index) => {
          // TODO update theme positions
          await updateSystemEmailTemplate({ variables: {
            id,
            position: index
          }})
      })).then(() => {
        refetchTemplates().then(() => setLoading(false))
      })
    }
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = templates[dragIndex]
      setState({
        ...state,
        templates:
        update(templates, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state],
  )

  return (
      <>
      <FilterBar 
        config={{ filterBy, actions, sortBy }} 
        callback={callback} 
        disabled={isReposition}
        sorts={sorts} filters={filters}
        tagKey='emails'
      />
      <Grid container direction="row" spacing={2} style={{ padding: 8*3 }}>
        {
          loading ? <CircularProgress color="secondary" /> :
          <>
            <RepositionBar {...state} callback={updateState} resetFilter={resetFilter} hasFilters={hasFilters}/>
            <Mutation mutation={DELETE_SYSTEM_EMAIL_TEMPLATE}>
            {(deleteSystemEmailTemplate, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.deleteSystemEmailTemplate && data.deleteSystemEmailTemplate.success) {
                refetchTemplates()
              }
           
              return templates.map((template,index) => {
                return (
                  <TemplateCard 
                    key={template.id}
                    updateFunc={UPDATE_SYSTEM_EMAIL_TEMPLATE}
                    to={'system_email_templates'}
                    index={index}
                    id={template.id}
                    moveCard={moveCard}
                    deleteFunc={deleteSystemEmailTemplate} 
                    template={template} 
                    isReposition={isReposition}
                    maxWidth={state.maxWidth}
                    resetThemeImage={null}
                    refetchTemplates={refetchTemplates}
                    toggleInputs = {false}
                  />
                )
              })
            }}
          </Mutation>
        </>
      }
      </Grid>
      </>
    )
  }

  export default SystemEmailTemplates
