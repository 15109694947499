const marketingLabels = {
  'name': '',
  'modals.site_code.button.done': '',
  'emails.sender.default_from_email': '',
  'dashboard.welcome_slides.social.title': '',
  'dashboard.welcome_slides.broadcast.title': '',
  'dashboard.welcome_slides.site_code.title': '',
  'dashboard.welcome_slides.broadcast.button': '',
  'dashboard.welcome_slides.site_code.button': '',
  'dashboard.welcome_slides.landing_page.title': '',
  'dashboard.welcome_slides.autoresponder.title': '',
  'dashboard.welcome_slides.landing_page.button': '',
  'dashboard.welcome_slides.autoresponder.button': '',
  'dashboard.welcome_slides.broadcast.description': '',
  'dashboard.welcome_slides.site_code.description': '',
  'dashboard.welcome_slides.landing_page.description': '',
  'dashboard.welcome_slides.autoresponder.description': ''
}

const marketingLabelsPlaceholders = name => ({
  'name': '',
  'modals.site_code.button.done': 'I Have Done It',
  'emails.sender.default_from_email': 'welcome@mail.mailmunch.io',
  'dashboard.welcome_slides.social.title': `Follow ${name}`,
  'dashboard.welcome_slides.broadcast.title': 'Send a Broadcast',
  'dashboard.welcome_slides.site_code.title': 'Add Site Code',
  'dashboard.welcome_slides.broadcast.button': 'Send Broadcast',
  'dashboard.welcome_slides.site_code.button': 'Get Code',
  'dashboard.welcome_slides.landing_page.title': 'Create a Landing Page',
  'dashboard.welcome_slides.autoresponder.title': 'Create a Autoresponder',
  'dashboard.welcome_slides.landing_page.button': 'Create Page',
  'dashboard.welcome_slides.autoresponder.button': 'Create Autoresponder',
  'dashboard.welcome_slides.broadcast.description': 'You have not sent a broadcast yet. Send a broadcast email to your subscribers and keep them engaged.',
  'dashboard.welcome_slides.site_code.description': `We have not detected ${name} site code on your site. Without this code, forms will not work on your site.`,
  'dashboard.welcome_slides.landing_page.description': 'You have no landing pages yet. Our pages can help you grow your email list quickly.',
  'dashboard.welcome_slides.autoresponder.description': 'You have no autoresponders yet. Create one to send a welcome email, lead magnet or a discount coupon.'
})

export {
  marketingLabels,
  marketingLabelsPlaceholders,
}