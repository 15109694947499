import React, { useState, useRef } from 'react'
import { 
  Grid, 
  Card,
  CardContent, 
  Typography, 
  CardActions, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  DialogContentText,
  CardMedia
} from '@material-ui/core'
import { useDrag, useDrop } from 'react-dnd'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit'
import DuplicateIcon from '@material-ui/icons/FileCopy'
import ItemTypes from './ItemTypes'
import Minidropdown from '../../common/MiniDropdown';

const MyCard = (props) => {
  const { card = {}, moveCard, onMenuItemSelect, handleEditorOpen, handleDelete, handleDuplicate } = props
  const [open,setOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState(null);

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = card.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the right
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      let drag = false
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
        drag = true
      }
      
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        drag = true
      }
      
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging rightwards
      if (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) {
        drag = true
      }
      // Dragging leftwards
      if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
        drag = true
      }

      if(drag) {
      // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      }
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id: card.id, index: card.index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const onDelete = () => {
    handleDelete()
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Grid ref={ref} item xs={6} sm={4}>
      <Card style={{ cursor: 'move', opacity }}>
        <CardContent style={{ position: 'relative', padding: '0px' }}>
          {/* <Typography align="center" color="textSecondary" component="div" gutterBottom>
            {card.id}
          </Typography> */}
          <div style={{ textAlign: 'right', maxHeight: '17px' }}>
            <MoreHorizIcon 
              style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
              size="small"
              onClick={({ currentTarget }) => setAnchorPoint(currentTarget)}
            >
            </MoreHorizIcon>
          </div>
          <div style={{ padding: '0px 16px 16px 16px' }}>
            <CardMedia
              style={{ width: '100%', height: 0, paddingTop: '100%' }}
              image={card.screenshotUrl}
            />
          </div>
        </CardContent>
        <CardActions>
          <Grid container alignItems="center" justify="center">
            <Grid item xs>
              <Button style={{ minWidth: 'auto' }} color="primary" size="small" onClick={() => { handleEditorOpen()}}><EditIcon/></Button>
            </Grid>
            <Grid item xs>
              <Button style={{ minWidth: 'auto' }} color="primary" size="small" onClick={handleDuplicate}><DuplicateIcon/></Button>
            </Grid>
            <Grid item xs>
              <Button style={{ minWidth: 'auto' }} color="secondary" size="small" onClick={() => { handleOpen()}}><DeleteIcon/></Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this page?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    To confirm deletion of this page, press Save on the Edit Template page.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    Cancel
                  </Button>
                  <Button onClick={onDelete} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </CardActions>
        <Minidropdown
          onMenuItemClick={(selectedOption) => {
            onMenuItemSelect(selectedOption);
            setAnchorPoint(null);
          }}
          onMenuClose={() => setAnchorPoint(null)}
          anchorPoint={anchorPoint}
          options={[
            { label: 'Copy JSON', value: 'copy' },
            { label: 'Edit JSON', value: 'edit' }
          ]}
        />
      </Card>
    </Grid>
  )
}

export default MyCard