export default {
  gdpr: {
    enabled: true, 
    features: {
      add_new_consent_field: { enabled: true }
    }
  },
    
  sites: {
    enabled: true, 
    features: {
      add_new: { enabled: true },
      overage: { enabled: false },
      switchable: { enabled: false },
      general_settings: { enabled: false },
      onboarding_wizard: { enabled: false },
    }
  },

  users: {
    enabled: false, 
    features: {
      sign_in: { enabled: false },
      sign_up: { enabled: false },
      forgot_password: { enabled: false },
    }
  },

  agency: {
    enabled: true, 
    features: {
      branding: { enabled: false }
    }
  },

  account: {
    enabled: false, 
    features: {
      billing: { enabled: false },
      api_keys: { enabled: false }
    },
  },

  widgets: {
    enabled: true, 
    features: {
      ab_testing: { enabled: false },
      builder_v2: { enabled: false },
      builder_premium_badges: { enabled: false }
    },
  },

  subscribers: {
    enabled: true, 
    features: {
      tags: { enabled: false },
      lists: { enabled: false },
      custom_fields: { enabled: false },
      add_new_custom_field: { enabled: false }
    },
  },
    
  integrations: {
    enabled: true, 
    features: {
      aweber: { enabled: false },
      zapier: { enabled: false },
      klaviyo: { enabled: false },
      httppost: { enabled: false },
      intercom: { enabled: false },
      sendlane: { enabled: false },
      mailchimp: { enabled: false },
      convertkit: { enabled: false },
      mailerlite: { enabled: false },
      salesforce: { enabled: false },
      getresponse: { enabled: false },
      infusionsoft: { enabled: false },
      activecampaign: { enabled: false },
      campaignmonitor: { enabled: false },
      constantcontact: { enabled: false },
    }
  },

  customer_support: {
    enabled: true, 
    features: {
      buttons: { enabled: false }
    },
  }
}