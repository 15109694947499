import React, { useEffect } from 'react'
import { Grid, Paper, InputLabel } from '@material-ui/core'
import ColorPicker from './ColorPicker'
import MyTextField from '../../common/input/textField'
import MyCheckbox from '../../common/input/checkbox'
import MySelect from '../../common/input/select'

const RenderInput = ({input, callback}) => {

  const props = {
    callback,
    ...input,
  }

  switch(input.type) {
    case 'number':
      return <MyTextField {...props}/>
    case 'checkbox':
      return <MyCheckbox {...props}/>
    case 'colorpicker': 
      return <ColorPicker {...props} update={(name, value) =>  callback({ [name]: value })}/>
    case 'select':
      return <MySelect {...props}/>
    default:
      return null
  }
}

const DefaultProperties = (props) => {

  const { data, type, callback }  = props;

  const {
    width = 500,
    animation = "mailmunch-swing", 
    show_on_entry = "1",
    overlay_color = "rgba(0,0,0,0.7)",
    box_position = 6,
    hide_scroll_away = "1", 
    scroll_percent_trigger = 50,
    sticky_top = "1",
    allow_closing = "1", 
    hide_duration = 1,
    show_at_bottom = "0",
    after_post = "1",
    always_show = "1", 
    before_post = "0",
    middle_post = "0",
  } = data || {}

  const popOver = [
    { label: 'width', name: 'width', type: 'number', value: width, xs: 6 },
    { label: 'Overlay color', name: 'overlay_color', type: 'colorpicker', value: overlay_color, xs: 6 },
    { 
      label: 'animation', name: 'animation', type: 'select', value: animation,
      options: [
        { name: 'mailmunch-rubberband', text: 'Rubberband' },
        { name: 'mailmunch-bounceInDown', text: 'Bounce In Down' },
        { name: 'mailmunch-swing', text: 'Swing' },
        { name: 'mailmunch-flyin', text: 'Flyin' },
        { name: 'mailmunch-tada', text: 'Tada' },
        { name: 'mailmunch-wobble', text: 'Wobble' },
      ], xs: 6,
    },
    { label: 'Show on entry', name: 'show_on_entry', type: 'checkbox', value: show_on_entry, xs: 6 },
  ]

  const scrollBox = [
    { label: 'width', name: 'width', type: 'number', value: width, xs: 6 },
    { label: 'Overlay color', name: 'overlay_color', type: 'colorpicker', value: overlay_color, xs: 6 },
    { 
      label: 'Box position', name: 'box_position', type: 'select', value: box_position,
      options: [
        { name: 4, text: 'Left' },
        { name: 6, text: 'Right' },
      ], xs: 6
    },
    { label: 'Hide scroll', name: 'hide_scroll_away', type: 'checkbox', value: hide_scroll_away, xs: 6 },
    { label: 'scroll percent trigger', min: 0, max: 100, name: 'scroll_percent_trigger', type: 'number', value: scroll_percent_trigger, xs: 6 },
  ]

  const topBar = [
    { label: 'width', name: 'width', type: 'number', value: width, xs: 6 },
    { label: 'Overlay color', name: 'overlay_color', type: 'colorpicker', value: overlay_color, xs: 6 },
    { label: 'Hide duration', name: 'hide_duration', type: 'number', value: hide_duration, xs: 6 },
    { label: 'Sticky Top', name: 'sticky_top', type: 'checkbox', value: sticky_top, xs: 6 },
    { label: 'Allow closing', name: 'allow_closing', type: 'checkbox', value: allow_closing, xs: 6 },
    { label: 'Show at bottom', name: 'show_at_bottom', type: 'checkbox', value: show_at_bottom, xs: 6 },
  ]

  const embedded = [
    { label: 'width', name: 'width', type: 'number', value: width, xs: 6 },
    { label: 'Overlay color', name: 'overlay_color', type: 'colorpicker', value: overlay_color, xs: 6 }, 
    { label: 'After post', name: 'after_post', type: 'checkbox', value: after_post, xs: 6 },
    { label: 'Always show', name: 'always_show', type: 'checkbox', value: always_show, xs: 6 },
    { label: 'Before post', name: 'before_post', type: 'checkbox', value: before_post, xs: 6 },
    { label: 'Middle post', name: 'middle_post', type: 'checkbox', value: middle_post, xs: 6 },
  ]

  const sideBar = [
    { label: 'width', name: 'width', type: 'number', value: width, xs: 6 },
    { label: 'Overlay color', name: 'overlay_color', type: 'colorpicker', value: overlay_color, xs: 6 },
    { label: 'Always show', name: 'always_show', type: 'checkbox', value: always_show, xs: 12 },
  ]

  const getForm = () => {
    switch(type.name) {
      case 'Cover':
        return popOver
      case 'Popover': 
        return popOver
      case 'Topbar':
        return topBar
      case 'Scrollbox':
        return scrollBox
      case 'Sidebar':
        return sideBar
      case 'Embedded':
        return embedded
      default:
        return null
    }
  }
  const form = getForm()

  useEffect(() => {
    callback({
      width,
      animation, 
      show_on_entry,
      overlay_color,
      box_position,
      hide_scroll_away,
      scroll_percent_trigger,
      sticky_top,
      allow_closing,
      hide_duration,
      show_at_bottom,
      after_post,
      always_show, 
      before_post,
      middle_post,
      includes: form.map(obj => obj.name)
    })
  },[type])

  const renderForm = form && form.map((item,index) => {
    return (
      <Grid key={index} item xs={item.xs ? item.xs : 'auto'}>
        <RenderInput callback={callback} input={item}/>
      </Grid>
    )
  })
  
  return (
    <React.Fragment>
      {
        form &&
        <React.Fragment>
          <InputLabel style={{ transform: 'translate(0, 1.5px) scale(0.75)'}} className="mt-10" htmlFor="defaultProperties">Default Properties</InputLabel>
          <hr/>
          <Paper style={{ padding: 16 }}>
            <Grid container spacing={4}>
              { renderForm }
            </Grid>
          </Paper>
        </React.Fragment>
      }
    </React.Fragment>
  )
}

export default DefaultProperties