
import { Link } from "react-router-dom"

const actions = [
  { label: 'New Email', component: Link, to: `/email_templates/new` }
]

const filterBy = [
  {
    name: 'name', label: 'Template name', type: 'textfield'
  },
  { name: 'tags', label: 'Tags', type: 'reactselect' },
  {
    name: 'premium', label: 'Price', type: 'select',
    options: [
      { value: '', label: 'All' },
      { value: true, label:'Premium' },
      { value: false, label:'Free' }
    ]
  },
  { 
    name: 'live', label: 'Status', type: 'select',
    options: [
      { value: '', label: 'All' },
      { value: true, label: 'Live' },
      { value: false, label: 'Inactive'}
    ]
  },
  { 
    name: 'webflowPublished', label: 'Webflow', type: 'select',
    options: [
      { value: '', label: 'All' },
      { value: true, label: 'Published' },
      { value: false, label: 'Unpublished'}
    ]
  }
]

const sortBy = [
  {
    label: 'Sort By', type: 'select', 
    options: [
      { value: 'POSITION', label: 'Position' },
      { value: 'NAME', label: 'Name' },
      { value: 'RECENT', label: 'Most Recent'}
    ]
  }
]

export { 
  actions, sortBy, filterBy
}