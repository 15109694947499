import React, { Component, useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CreatableSelect from 'react-select/creatable';
import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components'

const ActionBar = styled.div`
  display: flex;
  padding: 22px 0px 0px 26px;
`;

const ACTION_TYPES = {
  ADD_TAG : 'ADD_TAG',
  REMOVE_TAG : 'REMOVE_TAG'
}

const TagsModal = (props) => {
  const { tags, onSave, selectedCards, onBulkTagsRemove } = props;
  const [options, setOptions ] = useState([])
  const [snackbarStatus, setSnackbarStatus] = React.useState(false);
  const [ modalStatus, setModalStatus] = useState(false)
  const [selectedTags, setSelectedTags ] = useState([]);
  const [actionType, setActionType ] = useState('');
  
  const handleModalOpen = (action) => {
    if(selectedCards.length === 0) {
      setSnackbarStatus(true);
      return null
    }
    if(action === ACTION_TYPES.ADD_TAG) {
      setOptions(tags);  
    }
    
    if(action === ACTION_TYPES.REMOVE_TAG) {
      setOptions([... new Set(selectedCards.reduce((mergedTags, selectedCard) => mergedTags.concat(selectedCard.tags), []))])
    }
    
    setActionType(action);
    setModalStatus(true)
  }

  const handleModalClose = () => {
    setModalStatus(false)
    setOptions([]);
  }
  
  const handleOnSave = () => {
    
    if(actionType === ACTION_TYPES.ADD_TAG) {
      onSave(selectedTags);
    }
    
    if(actionType === ACTION_TYPES.REMOVE_TAG) {
      onBulkTagsRemove(selectedTags);
    }
    
    setSelectedTags([])
    handleModalClose()
  }
  
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarStatus}
        autoHideDuration={3000}
        onClose={() => {setSnackbarStatus(false)}}
        message="Select any template.">
      </Snackbar>
      <ActionBar>
        <Button color="primary" size="small" onClick={()=> handleModalOpen(ACTION_TYPES.ADD_TAG)}>Add Tag</Button>
        <Button color="secondary"  size="small" onClick={()=> handleModalOpen(ACTION_TYPES.REMOVE_TAG)}>Remove Tag</Button>
      </ActionBar>
      
      <Dialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={modalStatus}>
        <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
          Tags
        </DialogTitle>
        <DialogContent dividers style={{width: '500px', height: '400px'}}>
          <Typography gutterBottom>
            Please select tags to { actionType === ACTION_TYPES.ADD_TAG ? "add" : "remove"}
          </Typography>
          
          
          <FormControl margin="normal" style={{width: '100%'}}>
            <InputLabel className="MuiInputLabel-shrink" htmlFor="tags">Tags</InputLabel>
            {
              options &&
              <CreatableSelect
                isMulti
                id="tags"
                onChange={(event) => setSelectedTags(event)}
                options={options.map(item => ({ value: item, label: item }))}
                placeholder="Select tags"
                styles={{ 
                  control: styles => ({ ...styles, 
                    margin: 0,
                    marginTop: 16,
                    border: 0,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                    borderRadius: 0,
                    minHeight: 32,
                    boxShadow: 'none',
                    ':hover': {
                      borderColor: 'rgba(0, 0, 0, 0.42)',
                    },
                  }),
                  container: styles => ({
                    fontFamily: 'sans-serif',
                    zIndex: 2,
                  }),
                  indicatorsContainer: styles => ({
                    display: 'none',
                  }),
                  placeholder: styles => ({
                    color: "#acacac",
                    fontWeight: "100",
                    fontSize: "14px",
                    position: 'absolute',
                    left: '4px',
                  }),
                  valueContainer: styles => ({
                    ...styles,
                    paddingLeft: '2px'
                  }),
                }}
              />
            }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnSave} color="primary">Update</Button>
          <Button autoFocus color="secondary" onClick={handleModalClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TagsModal;