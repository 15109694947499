import React, { useImperativeHandle, useRef, useState } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import ItemTypes from './ItemTypes'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Grid, Input, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CardMedia } from '@material-ui/core/'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DuplicateIcon from '@material-ui/icons/FileCopy'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Minidropdown from '../../common/MiniDropdown';
  
const style = {
  cursor: 'move',
}
const MyCard = React.forwardRef(
  ({ card, isDragging, onJsonMenuClicked, connectDragSource, connectDropTarget, updateCard, handleEditorOpen, handleDelete, handleDuplicate, onMenuItemSelect }, ref) => {
    
    const [open, setOpen] = useState(false)
    const [anchorPoint, setAnchorPoint] = useState(null);

    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))


    const onDelete = () => {
      handleDelete()
      handleClose()
    }
    const handleClose = () => {
      setOpen(false)
    }

    const handleOpen = () => {
      setOpen(true)
    }
    return (
      <Grid style={style} key={`page-${card.position}`} item xs={6} sm={4}>
        <div style={{ display: 'flex' }} ref={elementRef}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" component="div" gutterBottom>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Input
                    inputProps={{
                      style: { textAlign: 'center'}
                    }}
                    placeholder="name"
                    value={card.name}
                    onChange={(e) => {
                      updateCard('name',e.target.value)
                    }}
                  />
                  <MoreHorizIcon 
                    style={{ width: '20px', height: '20px', cursor: 'pointer', marginTop: 6 }} 
                    size="small"
                    onClick={({ currentTarget }) => {
                      onJsonMenuClicked();
                      setAnchorPoint(currentTarget);
                    }}>
                  </MoreHorizIcon>
                </div>
              </Typography>
              <CardMedia
                style={{ width: '100%', height: 0, paddingTop: '100%' }}
                image={card.screenshotUrl}
              />
            </CardContent>
            <CardActions>
              <Grid container alignItems="center" justify="center">
                <Grid item xs>
                  <Button style={{ minWidth: 'auto' }} color="primary" size="small" onClick={() => { handleEditorOpen()}}><EditIcon/></Button>
                </Grid>
                <Grid item xs>
                  <Button style={{ minWidth: 'auto' }} color="primary" size="small" onClick={handleDuplicate}><DuplicateIcon/></Button>
                </Grid>
                <Grid item xs>
                  <Button style={{ minWidth: 'auto' }} color="secondary" size="small" onClick={() => { handleOpen()}}><DeleteIcon/></Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this page?"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        To confirm deletion of this page, press Save on the Edit Template page.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary" autoFocus>
                        Cancel
                      </Button>
                      <Button onClick={onDelete} color="primary">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </CardActions>
            <Minidropdown
              onMenuItemClick={(selectedOption) => {
                onMenuItemSelect(selectedOption);
                setAnchorPoint(null);
              }}
              onMenuClose={() => setAnchorPoint(null)}
              anchorPoint={anchorPoint}
              options={[
                { label: 'Copy JSON', value: 'copy' },
                { label: 'Edit JSON', value: 'edit' }
              ]}
            />
          </Card>
        </div>
      </Grid>
    )
  },
)
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null
      }
      // node = HTML Div element from imperative API
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect()
      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the right
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging rightwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      // Dragging leftwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex
    },
  },
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: props => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(MyCard),
)
