import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import CircularProgress from '@material-ui/core/CircularProgress';


const useCustomMediaStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    "& .mediaButton": {
      display: 'none',
    },
    "&:hover, &:focus": {
      "& .mediaButton": {
        display: 'flex',
      },
      backgroundColor: emphasize('#fff', 0.08)
    },
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '#ffffffd6',
  },
});

const CustomMedia = ({
  color,
  backgroundColor,
  hoverBackgroundColor,
  children,
  onEdit,
  onReset,
  progress,
  ...rest
}) => {
  const classes = useCustomMediaStyles({
    color,
    backgroundColor,
    hoverBackgroundColor
  });
  return (
    <div className={classes.container} {...rest}>
      {children}
      {
        progress ?
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute', 
            width: '100%', 
            height: '100%', 
            background: 'rgba(255,255,255,0.9)'
          }}
        >
          <CircularProgress />
        </div> :
        <div className={`${classes.buttonContainer} mediaButton`}>
          <Button color="primary" size="small" onClick={onReset}>reset</Button>
          <Button color="primary" size="small" onClick={onEdit}>edit</Button>
        </div>
      }
    </div>
  )
};

const Pixie = ({ id, children, image = '', onReset, progress }) => {
  const handleClickOpen = () => {
    window.templateId = id
    window.pixie.resetAndOpenEditor({
      image: `${image}?t=${new Date().getTime()}`
    });
  };

  return (
    <React.Fragment>
      <CustomMedia onEdit={handleClickOpen} onReset={() => onReset(id)} progress={progress}>
        {
          children
        }
      </CustomMedia>
    </React.Fragment>
  )
}

export default Pixie