import React from 'react'
import { Redirect } from "react-router-dom"

import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"
import FETCH_SYSTEM_EMAIL_TEMPLATE from '../../../graphql/query/fetchSystemEmailTemplate'
import Editor from './Editor'

const UPDATE_SYSTEM_EMAIL_TEMPLATE = gql`
  mutation UpdateSystemEmailTemplate($id: Int!, $name: String, $slug:String ,$position: Int, $json: JSON, $html: String, $tags: JSON) {
    updateSystemEmailTemplate(input: { id: $id, name: $name, position: $position, slug:$slug ,json: $json, html: $html, tags: $tags }) {
      success
      systemEmailTemplate {
        id
        name
        slug
        position
        json
        tags
      }
    }
  }
`

export default class EditTemplate extends React.Component {
  render() {
    const { match: { params }, templates, slugs } = this.props
    const templateId = parseInt(params.id)
    //const template = templates.find((t) => t.id === templateId)

    return (
      <Query query={FETCH_SYSTEM_EMAIL_TEMPLATE} variables={{ id: templateId }} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { systemEmailTemplate: template } = data
          
          return (
            <Mutation mutation={UPDATE_SYSTEM_EMAIL_TEMPLATE}>
            {(updateSystemEmailTemplate, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.updateSystemEmailTemplate && data.updateSystemEmailTemplate.success) {
                return <Redirect to="/system_email_templates" />
              }

              return (
                <div>
                  <Editor templates={templates} slugs={slugs} template={template} onSave={(data) => {
                    updateSystemEmailTemplate({
                      variables: {
                        id: templateId,
                        html: data.html,
                        name: data.name,
                        position: data.position,
                        slug: data.slug === "none" ? null : data.slug,
                        json: data.json,
                        tags: data.tags
                      },
                      refetchQueries: [
                        'getCurrentUser'
                      ]
                    })
                  }} />
                </div>
              )
            }}
            </Mutation>
          )
        }}
        </Query>
      )
  }
}
