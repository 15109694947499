import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import featuresList from './data/featuresList';
import { marketingLabels, marketingLabelsPlaceholders } from './data/marketingLabels';
import logos from './data/logos';
import emailSettings from './data/emailSettings';

import FeatureSwitch from './FeatureSwitch';
import Domains from './Domains';
import MarketingLabels from './MarketingLabels';
import Logos from './Logos';
import EmailSettings from './EmailSettings';

import UPDATE_PARTNER_PROFILE from '../../graphql/mutation/updatePartnerProfile';
import FETCH_PARTNER from '../../graphql/query/fetchPartner';

import { groupMarketingLabels, groupLogos, sortSmtpSettings } from '../../utils';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: 30,
  },
  heading: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    marginTop: 10,
  },
  container: {
    ...theme.container,
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0px',
  },
  block: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: '150%',
    width: '100%',
    zIndex: 990,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    boxShadow: '0 0 5px 10px rgba(255, 255, 255, 0.5)',
    display: 'none',
    justifyContent: 'center',
  },
  loader: {
    marginTop: 300,
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: 1000,
  },
  tab: {
    '& > * > * > *': {
      justifyContent: 'space-around',
    }
  },
  logosContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  domainContainer: {
    columnCount: 2,
    columnGap: 155,
    width: '100%'
  },
  emailSettingsContainer: {
    columnCount: 2,
    columnGap: 155,
    width: '100%'
  },
  button: {
    float: 'right',
  },
  progress: {
    display: 'flex',
    '& > div': {
      color: 'white',
    },
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    marginRight: 10,
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const Settings = ({ partner, ...props }) => {
  const classes = useStyles();
  const [tabState, setTabState] = useState(0);
  const [snackBarState, setSnackBarState] = useState({
      snackBarOpen: false,
      vertical: 'top',
      horizontal: 'center',
  });
  const [errors, setErrors] = useState({
      domain: {
        cdnDomain : false,
        appDomain: false,
        pagesDomain: false,
        formsDomain: false,
      },
    emailSettings: {
      port: false,
      address: false,
    }
  });
  const [updatePartnerProfile, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_PARTNER_PROFILE, {
    onCompleted: data => {
      setSnackBarState({ ...snackBarState, snackBarOpen: true });
      document.getElementById('blockDiv').style.display = 'flex'; 
      setTimeout(() => {
        const { history } = props;
        history.push('/');
      }, 3000);
    },
    onError: data => {
      setSnackBarState({ ...snackBarState, snackBarOpen: true });
    }
  });
  const [state, setState] = useState({});
  const { loading, error } = useQuery(FETCH_PARTNER, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const { currentPartner = {} } = data;
      setState({
        labels: !!Object.keys(currentPartner.labels).length ? currentPartner.labels : marketingLabels,
        logo: currentPartner.logo || logos,
        platformFeatures: !!Object.keys(currentPartner.platformFeatures).length ? currentPartner.platformFeatures : featuresList,
        cdnDomain: currentPartner.cdnDomain || '',
        appDomain: currentPartner.appDomain || '',
        pagesDomain: currentPartner.pagesDomain || '',
        formsDomain: currentPartner.formsDomain || '',
        emailSettings: !!Object.keys(currentPartner.emailSettings).length ? currentPartner.emailSettings : emailSettings,
      })
    },
  });
  const setStateWrapper = (key, value) => {
    let newState = Object.assign({}, state)
    newState[key] = value
    setState(newState)
  }

  const changeTabState = (event, newValue) => {
    setTabState(newValue);
  };

  const handleLabelsChange = event => {
    const { name, value } = event.target
    const newLabels = {
      ...state.labels,
      [name]: value
    }
    setStateWrapper('labels', newLabels)
  }
  
  const handleDomainChange = event => {
    const { name, value } = event.target
    let newErrors = {
      ...errors,
      'domain': {
        ...errors.domain,
        [name]: false
      }
    }
    setErrors(newErrors)
    setStateWrapper(name, value)
  }
  
  const handleEmailSettingsChange = event => {
    const { name, value, checked } = event.target
    let newErrors = {
      ...errors,
      'emailSettings': {
        ...errors.emailSettings,
        [name]: false
      }
    }
    const newEmailSettings = {
      ...state.emailSettings,
      smtpSettings: {
        ...state.emailSettings.smtpSettings,
        [name]: name === 'enableStarttlsAuto' ? checked : value
      },
    }
    setErrors(newErrors)
    setStateWrapper('emailSettings', newEmailSettings)
  }

  const handleDomainBlur = event => {
    const { name, value } = event.target
    const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;
    const urlPattern = new RegExp(expression);
    if(!urlPattern.test(value)){
      let newErrors = {
        ...errors,
        'domain': {
          ...errors.domain,
          [name]: true
        }
      }
      setErrors(newErrors)
    } 
  }
  
  const handleEmailSettingsBlur = event => {
    event.preventDefault()
    const { name, value } = event.target
    if(name === 'address') {
      const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/;
      const urlPattern = new RegExp(expression);
      if(!urlPattern.test(value)){
        let newErrors = {
          ...errors,
          'emailSettings': {
            ...errors.emailSettings,
            [name]: true
          }
        }
        setErrors(newErrors)
      } 
    }
  }
  
  const handleLogosChange = (name, value) => {
    const newLogo = {
      ...state.logo,
      [name]: {
        url: value,
      }
    }
    setStateWrapper('logo', newLogo)
  }
  
  const handleFeatureChange = label => event => {
    event.cancelBubble = true;
    event.stopPropagation();
    const newFeatures = {
      ...state.platformFeatures,
      [label]: {
        ...state.platformFeatures[label],
        enabled: event.target.checked,
      }
    }
    setStateWrapper('platformFeatures', newFeatures)
  }
  
  const handleDetailsChange = (label, feature) => event => {
    const newFeatures = {
      ...state.platformFeatures,
      [label]: {
        ...state.platformFeatures[label],
        features: {
          ...state.platformFeatures[label].features,
          [feature]: { enabled: event.target.checked }
        }
      }
    }
    setStateWrapper('platformFeatures', newFeatures)
  }

  const handleSave = event => {
    event.preventDefault();
    const { id } = partner;
    updatePartnerProfile({
      variables: { id, ...state },
    });
  }
  
  const handleCancel = event => {
    event.preventDefault();
    const { history } = props;
    history.push('/');
  }
  
  const handleSnackBarClose = event => {
    event.preventDefault();
    setSnackBarState({ ...snackBarState, snackBarOpen: false });
  };
  
  if (loading || !Object.keys(state).length) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  
  return (
    <Container className={classes.container}>
      <div id="blockDiv" className={classes.block}>
        <CircularProgress className={classes.loader} size={80} />
      </div>
      <form noValidate autoComplete="off">
        <Typography className={classes.title} variant="h4" color="inherit" >
          <Snackbar
            anchorOrigin={{ vertical: snackBarState.vertical, horizontal: snackBarState.horizontal }}
            key={`${snackBarState.vertical},${snackBarState.horizontal}`}
            open={snackBarState.snackBarOpen}
            onClose={mutationError ? handleSnackBarClose : undefined}
          >
            <MuiAlert elevation={6} variant="filled" onClose={mutationError ? handleSnackBarClose : undefined} severity={(mutationError ? "error" : "success")}>
              {!mutationError ? "Changes saved successfully!" : "Changes could not be saved. Please try again."}
            </MuiAlert>
          </Snackbar>
          Partner Account Settings
          <Button
            disabled={Object.values({...errors.domain, ...errors.emailSettings}).includes(true)}
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            { mutationLoading && ( 
            <div className={classes.progress}>
              <CircularProgress size={14} />
            </div> )} Save
          </Button>
          <Button
            style={{"marginRight": 50}}
            className={classes.button}
            size="large"
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Typography>
        <div className={classes.tabContainer}>
          <AppBar position="static" className={classes.tab}>
            <Tabs value={tabState} onChange={changeTabState} aria-label="simple tabs example">
              <Tab label="NAME AND MARKETING LABEL" />
              <Tab label="LOGOS" />
              <Tab label="FEATURES" />
              <Tab label="ADVANCED CONFIGURATIONS" />
              <Tab label="EMAIL SETTINGS" />
            </Tabs>
          </AppBar>
          <TabPanel value={tabState} index={0}>
          <div className={classes.heading}>Name and Marketing Label</div>
            {
              Object.keys(groupMarketingLabels(state.labels)).map((label, index) => {
                return (
                  <MarketingLabels
                    key={`labels-${index}`}
                    label={label}
                    placeholders={marketingLabelsPlaceholders(partner.name)}
                    values={groupMarketingLabels(state.labels)[label]}
                    handleChange={handleLabelsChange}
                  />
                )
              })
            }
          </TabPanel>
          <TabPanel value={tabState} index={1}>
            <div className={classes.heading}>Logos</div>
            <div className={classes.logosContainer}>
            {
              groupLogos(state.logo).map((logosGroup, index) => {
                return (
                  <Logos
                    key={`logogroup-${index}`}
                    logosGroup={logosGroup}
                    partner={partner}
                    handleImageChange={handleLogosChange}
                  />
                )
              })
            }
            </div>
          </TabPanel>
          <TabPanel value={tabState} index={2}>
            <div className={classes.heading}>Features</div>
            {
              Object.keys(state.platformFeatures).map((key, index) => {
                return (
                  <FeatureSwitch 
                    key={`features-${index}`}
                    label={key}
                    featureDetails={state.platformFeatures[key]}
                    handleFeatureChange={handleFeatureChange}
                    handleDetailsChange={handleDetailsChange}
                  />
                )
              })
            }
          </TabPanel>
          <TabPanel value={tabState} index={3}>
            <div className={classes.heading}>Advanced Configurations</div>
            <div className={classes.domainContainer}>
              {
                Object.keys({'Cdn': state.cdnDomain, 'App': state.appDomain, 'Pages': state.pagesDomain, 'Forms': state.formsDomain}).
                map((key, index) => {
                  return (
                    <Domains
                      key={`features-${index}`}
                      label={key}
                      value={state[key.toLowerCase()+'Domain']}
                      name={key.toLowerCase()+'Domain'}
                      error={errors.domain[key.toLowerCase()+'Domain']}
                      handleBlur={handleDomainBlur}
                      handleChange={handleDomainChange}
                    />
                  )
                })
              }
            </div>
          </TabPanel>
          <TabPanel value={tabState} index={4}>
            <div className={classes.heading}>Email Settings</div>
            <div className={classes.emailSettingsContainer}>
            {
              Object.keys(sortSmtpSettings(state.emailSettings.smtpSettings)).
              map((key, index) => {
                return (
                  <EmailSettings
                    key={`emailsettings-${index}`}
                    name={key}
                    type={key === 'password' ? 'password' : 'text'}
                    value={state.emailSettings.smtpSettings[key]}
                    error={errors.emailSettings[key]}
                    handleBlur={handleEmailSettingsBlur}
                    handleChange={handleEmailSettingsChange}
                  />
                )
              })
            }
            </div>
          </TabPanel>
        </div>
      </form>
    </Container>
  );
};

export default Settings;
