import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  domainRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Domains = ({ label, name, value, handleChange, error, handleBlur }) => {
  const classes = useStyles();
  
  return (
    <TextField
      label={`${label === 'Cdn' ? label.toUpperCase() : label} Domain`}
      name={name}
      value={value}
      error={error}
      helperText={error &&"Incorrect entry."}
      onBlur={handleBlur}
      onChange={handleChange}
      style={{ width: 350 }}
      placeholder={label === 'Cdn' ? label.toUpperCase() : label}
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
    />
  );
};

export default Domains;
