import React from 'react'
import { Redirect } from "react-router-dom"

import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"

import Editor from './Editor'

import FETCH_EMAIL_TEMPLATES_COUNT from '../../../graphql/query/fetchEmailTemplatesCount'

const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate($name: String!, $position: Int, $live: Boolean, $premium: Boolean, $json: JSON, $html: String, $unrollTemplateId: Int, $designFormat: String!, $tags: JSON, $slug: String) {
    createEmailTemplate(input: { name: $name, position: $position, live: $live, premium: $premium, json: $json, html: $html, unrollTemplateId: $unrollTemplateId, designFormat: $designFormat, tags: $tags, slug: $slug }) {
      success
      emailTemplate {
        id
        name
        premium
        position
        live
        json
        tags
        unrollTemplateId
        designFormat
        slug
      }
    }
  }
`;

export default class NewTemplate extends React.Component {
  render() {
    const { refetchTemplates } = this.props
    return (
      <Query query={FETCH_EMAIL_TEMPLATES_COUNT} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { totalEmailTemplatesCount: templatesCount } = data
          
          return (
            <Mutation mutation={CREATE_EMAIL_TEMPLATE}>
            {(createEmailTemplate, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.createEmailTemplate && data.createEmailTemplate.success) {
                refetchTemplates()
                return <Redirect to="/email_templates" />
              }

              return (
                <Editor templatesCount={templatesCount} onSave={(data) => {
                  createEmailTemplate({
                    variables: data,
                    refetchQueries: [
                      'getCurrentUser'
                    ]
                  })
                }}/>
              )
            }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}
