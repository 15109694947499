import { useState, useEffect } from 'react'

const groupMarketingLabels = labels => {
  let grouped = {
    'landing_page': [],
    'autoresponder': [],
    'broadcast': [],
    'other': [],
  }

  Object.keys(labels).map(label => {
    let other = true;
    Object.keys(grouped).map(group => {
      if(label.indexOf(group) > -1) {
        grouped[group].push({[label]: labels[label]})
        other = false;
      }
    })
    if(other) grouped['other'].push({[label]: labels[label]})
  })

  Object.keys(grouped).map(value => {
    const leftOverValues = grouped[value].length % 2;
    const indexFirst = ( grouped[value].length - leftOverValues ) / 2;
    let first = grouped[value][indexFirst];
    let indexSecond;
    if(value === 'landing_page') {
      indexSecond = grouped[value].findIndex(val => Object.keys(val)[0] === 'dashboard.welcome_slides.landing_page.description');
    } else if (value === 'autoresponder') {
      indexSecond = grouped[value].findIndex(val => Object.keys(val)[0] === 'dashboard.welcome_slides.autoresponder.description');
    } else if (value === 'broadcast') {
      indexSecond = grouped[value].findIndex(val => Object.keys(val)[0] === 'dashboard.welcome_slides.broadcast.description');
    } else {
      indexSecond = grouped[value].findIndex(val => Object.keys(val)[0] === 'dashboard.welcome_slides.site_code.description');
    }
    if(indexSecond > -1 && first) {
      grouped[value][indexFirst] = grouped[value][indexSecond];
      grouped[value][indexSecond] = first;
    }
  })

  // * if any of the groups do not have any label, remove them
  const nonEmptyGroupedLabels = Object.keys(grouped)
    .filter(group => !!grouped[group].length)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: grouped[key]
      };
    }, {});

  return nonEmptyGroupedLabels;
}

const groupLogos = logos => {
  let one = {};
  let two = {};
  
  Object.keys(logos).map((key, index) => {
    if(index < 3) {
      one[key] = logos[key];
    } else {
      two[key] = logos[key];
    }
  })
  const grouped = [
    one, two
  ]
    return grouped;
}

const sortSmtpSettings = smtpSettings => {
  const orderedFields = ['userName', 'address', 'authentication', 'password', 'port', 'enableStarttlsAuto'];
  const newEmailSettings = {};
  for(let field of orderedFields) {
    for(let item in smtpSettings) {
      if(item === field) {
        newEmailSettings[item] = smtpSettings[item]
      }
    }
  }
  return newEmailSettings;
}

const parseLabel = label => {
  return label.replace(/[._]/g, " ")
}


function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const copyToClipboard = (value) => {
  return navigator.permissions.query({ name: 'clipboard-write' }).then(({ state }) => {
    if (state === 'granted') {
      return navigator.clipboard.writeText(value)
        .then(() => {
          console.log('Content Copied to clipboard successfully !');
          return Promise.resolve();
        })
        .catch((e) => {
          console.log('ERROR IN COPYING TO CLIPBOARD', e);
          return Promise.reject();
        });
    }
    return Promise.reject();
  }).catch((error) => {
    console.log('PERMISSION DENYED FOR COPYING TO CLIPBOARD ', error);
    return Promise.reject();
  })
};


export {
  groupMarketingLabels,
  groupLogos,
  sortSmtpSettings,
  parseLabel,
  useDebounce,
  copyToClipboard
}
