import gql from "graphql-tag"

const IS_EMAIL_TEMPLATE_SLUG_AVAILABLE = gql`
  query isEmailTemplateSlugAvailable($id: Int, $slug: String!) {  
    isEmailTemplateSlugAvailable(id: $id, slug: $slug) {
      success
    }
  }
`;

export default IS_EMAIL_TEMPLATE_SLUG_AVAILABLE