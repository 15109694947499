export default {
  'support': '',
  'formsMarketing': '',
  'landingPagesMarketing': '',
  'buttonClickPopupArticle': '',
  'sender': '',
  'headers': {
  },
  'fromName': '',
  'fromEmail': '',
  'smtpSettings': {
    'port': '',
    'address': '',
    'password': '',
    'userName': '',
    'authentication': '',
    'enableStarttlsAuto': true,
  }
}
  