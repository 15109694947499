import React from 'react'
import { Grid, Button, Slider, Typography } from '@material-ui/core'
import ZoomIn from '@material-ui/icons/ZoomIn'
import ZoomOut from '@material-ui/icons/ZoomOut'
import ReplayIcon from '@material-ui/icons/Replay';

const RepositionBar = ({ isReposition, maxWidth, callback, resetFilter, hasFilters }) => {
  const onCancel = () => {
    callback({ isReposition: false, maxWidth: 350 }, false, true)
  }

  const onUpdate = () => {
    callback({ isReposition: false, maxWidth: 350 }, true)
  }

  const onRepositionClick = () => {
    callback({ isReposition: true, maxWidth: 210 })
  }

  const handleChange = (event, value) => {
    callback({ maxWidth: value})
  }
  
  return (
    <Grid container item alignItems="center">
      <Grid item xs={12}>
        {
          isReposition ?
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="default" onClick={onCancel}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={onUpdate}>Update</Button>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <ZoomOut />
                </Grid>
                <Grid item xs>
                  <Slider value={maxWidth} min={100} max={350} onChange={handleChange} style={{ width: 300 }}/>
                </Grid>
                <Grid item>
                  <ZoomIn />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          :
          <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onRepositionClick}>Reposition Templates</Button>
            </Grid>
            {
              hasFilters && 
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography variant="caption" style={{ color: 'red'}}>The current view does not match the true positioning of the templates in the app. <span onClick={() => resetFilter()} style={{  textDecoration: 'underline' , cursor: 'pointer'}}>RESET VIEW</span></Typography>
                  </Grid>
                  <Grid item>
                    <ReplayIcon style={{ color: 'red'}}/> 
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default RepositionBar