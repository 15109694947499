import React from 'react'
import { Paper, Typography, Grid, Button, Divider } from '@material-ui/core/'
import SortByField from './SortByField'
import MyReactSelect from './MyReactSelect'
import MySelectField from './MySelectField'
import MyTextField from './MyTextField'


const FilterBar = ({ callback, disabled, config: { actions = [], filterBy = [], sortBy = [] } = {}, sorts = {}, filters = {}, tagKey }) => {

  const renderFilters = filterBy.map((item, index) => {
    const { name, type, ...props } = item
    const value = filters[item.name]
    const onChange = (value) => callback('filterBy',value)

    const inputProps = {
      name,
      onChange,
      disabled,
      value,
      tagKey,
      ...props,
    }

    const input = () => {
      switch (type) {
        case 'autoComplete': 
          return <MyTextField {...inputProps}/>
        case 'textfield':
          return <MyTextField {...inputProps}/>
        case 'select':
          return <MySelectField {...inputProps}/>
        case 'reactselect':
          return <MyReactSelect {...inputProps} />
        default:
          return null
      }
    }
    return (
      <Grid item key={index}>
        { input() }
      </Grid>
    )
  })

  const renderSortBy = sortBy.map((item,index) => {
    const { type, ...props } = item
    const onChange = (value) => callback('sortBy',value)
    const value = sorts

    const inputProps = {
      onChange,
      disabled,
      value,
      ...props,
    }

    const input = () => {
      switch (type) {
        case 'button': 
          return <SortByField {...inputProps}/>
        case 'select':
          return <MySelectField {...inputProps}/>
        default:
          return null
      }
    }
    return (
      <Grid item key={index}>
        { input() }
      </Grid>
    )
  })

  const renderActions = actions.map(({label , component, to }, index) => {
    return (
      <Grid key={index} item>
        <Button variant="contained" color="secondary" disabled={disabled} component={component} to={to}>
          {label}
        </Button>
      </Grid>
    )
  })

  return (
    <Paper style={{ padding: 5 }}>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item xs>
          <Grid container alignItems="center" spacing={4} style={{ padding: '0 24px 0 24px', position: 'relative', zIndex: 991}}>
            <Grid item>
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item>
                  <Typography>Filter by</Typography>
                </Grid>
                {
                  renderFilters
                }
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid item>
                  <Divider style={{ width: 2, height: 60 }}orientation="vertical"/>
                </Grid>
                {
                  renderSortBy
                }
                <Grid item>
                  <Divider style={{ width: 2, height: 60 }}orientation="vertical"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center" justify="center" style={{ padding: '0 24px 0 24px'}}>
            {
              renderActions
            }
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FilterBar