import gql from "graphql-tag"

const FETCH_SYSTEM_EMAIL_TEMPLATE = gql`
query SystemEmailTemplate($id: Int!) {  
  systemEmailTemplate(id: $id) {
    id
    name
    position
    json
    screenshotUrl
    slug
    tags
  }
}
`

export default FETCH_SYSTEM_EMAIL_TEMPLATE