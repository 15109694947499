import React from 'react';
import styled from 'styled-components'

import runtimeEnv from '@mars/heroku-js-runtime-env'
import { UserContext } from '../../UserContext'

const env = runtimeEnv();

/** Generates font sizes for unlayer text editor tool*/
const generateFontSizes = () => {
  const fontSizes = [];

  for(var i = 8; i <= 100; i > 46 ? i = i+4 : i = i +2 ) {
    fontSizes.push(`${i}px`);
  }   

  return fontSizes;
}


const Wrapper = styled.div`
  flex: 1;
  display: flex;
`

const Editor = styled.div`
  flex: 1;
  display: flex;
  
  > iframe {
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: ${props => props.minHeight || '500px'} !important;
    display: flex;
    border: 0px;
  }
`

class UnlayerEditor extends React.Component {
  componentDidMount() {
    const { displayMode, widgetType, linkTypes: preLinkTypes = [] } = this.props
    
     // Button tool link types
    let linkTypes = [...preLinkTypes]
    if (widgetType && widgetType.name != 'Embedded') {
      linkTypes.push({
        name: 'close_form',
        label: `Close ${widgetType.name}`,
        attrs: {
          onClick: 'closeWidget()'
        }
      })
    }

    const productTags = {
      name: {
        name: "Product Name",
        value: "{{product_name}}",
        sample: "Product Name"
      },
      image: {
        name: "Product Image Url",
        value: "{{product_image_url}}",
        sample: "https://via.placeholder.com/500x100?text=IMAGE"
      },
      currency: {
        name: "Product Currency",
        value: "{{product_currency}}",
        sample: "Product Currency"
      },
      price: {
        name: "Product Price",
        value: "{{product_price}}",
        sample: "Product Price"
      },
    }

    const automationTags = {
      ...productTags,
      recoverCartUrl: {
        name: 'Recover Cart Url', 
        value: `{{recover_cart_url}}`
      }
    }

    const mergeTags = displayMode === 'email' ? {
      ...automationTags,
      products: {
        name: "Products",
        rules: {
          repeat: {
            name: "Repeat for Each Product",
            before: "{{#products}}",
            after: "{{/products}}",
          }
        },
        mergeTags: {
          ...productTags
        }
      }
    } : {}

    let customJS = [env.REACT_APP_UNLAYER_FORM_TOOL,env.REACT_APP_UNLAYER_SPINWHEEL_TOOL]
    let user = this.context
    window.unlayer.init({
      id: 'editor',
      displayMode,
      linkTypes,
      designMode: 'edit',
      projectId: 1,
      customJS: customJS,
      features: {
        textEditor: {
          fontSizes: generateFontSizes()
        }
      },
      mergeTags,
      user: {
        id: user.id,
      },
      tools: {
        form: {
          enabled: false,
        },
      }
    })
    if (this.props.templateId) window.unlayer.loadTemplate(this.props.templateId)
    else if (this.props.json) window.unlayer.loadDesign(this.props.json)
  }

  render() {
    return (
      <Wrapper>
        <Editor
          id="editor"
          style={this.props.style}
          minHeight={this.props.minHeight}
        />
      </Wrapper>
    )
  }
}

UnlayerEditor.contextType = UserContext
export default UnlayerEditor;