import gql from "graphql-tag"

const FETCH_THEME = gql`
query Theme($id: Int!) {  
  theme(id: $id) {
      id
      name
      premium
      position
      live
      partnerId
      closeButton
      designFormat
      defaultProperties
      screenshotUrl
      widgetTypeId
      steps {
        id
        name
        position
        json
        screenshotUrl
        html
        css
      }
      tags
    }
  }
`

export default FETCH_THEME