import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { parseLabel } from '../../utils';

const useStyles = makeStyles(theme => ({
  labelContainer: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    padding: 15,
    marginTop: 25,
    borderRadius: 10,
  },
  textFieldContainer: {
    columnCount: 2,
    columnGap: 120,
  },
  textField: {
    '& > label': {
      textTransform: 'capitalize',
      width: '120%'
    }
  }
}));

const MarketingLabels = ({ label, values, placeholders, handleChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.labelContainer}>
      <Typography variant="overline" display="block" gutterBottom>
        {label}
      </Typography>
      <div className={classes.textFieldContainer}>
      {
        values.map((item, index) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          const leftOverValues = values.length % 2;
          const bottomLeftIndex = ( values.length - leftOverValues ) / 2;
          return (
            <TextField
              className={classes.textField}
              key={`label-${index}`}
              label={parseLabel(key)}
              name={key}
              value={value}
              onChange={handleChange}
              style={{ width: (index === bottomLeftIndex && !!leftOverValues ? 870 : 350) }}
              placeholder={placeholders[key]}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              variant="outlined"
            />
          );
        })
      }
      </div>
    </div>
  );
};

export default MarketingLabels;
