import gql from "graphql-tag"

const UPDATE_THEME = gql`
  mutation updateTheme($id: Int!, $name: String, $position: Int, $live: Boolean, $premium: Boolean, $closeButton: JSON, $defaultProperties: JSON, $steps: [UpdateThemeStepInput], $screenshotUrl: String, $tags: JSON ) {
    updateTheme(input: { id: $id, name: $name, position: $position, live: $live, premium: $premium, closeButton: $closeButton, defaultProperties: $defaultProperties, steps: $steps, screenshotUrl: $screenshotUrl, tags: $tags }) {
      success
      theme {
        id
        name
        position
        steps {
          id
          name
          position
          json
          screenshotUrl
        }
        live
        premium
        closeButton
        defaultProperties
        screenshotUrl
        tags
      }
      error
    }
  }
`

export default UPDATE_THEME