import gql from "graphql-tag"

const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($id: Int!, $name: String, $position: Int, $live: Boolean, $premium: Boolean, $json: JSON, $unrollTemplateId: Int, $designFormat: String, $tags: JSON, $screenshotUrl: String, $slug: String, $webflowSettings: JSON) {
    updateEmailTemplate(input: { id: $id, name: $name, position: $position, live: $live, premium: $premium, json: $json, unrollTemplateId: $unrollTemplateId, designFormat: $designFormat, tags: $tags, screenshotUrl: $screenshotUrl, slug: $slug, webflowSettings: $webflowSettings }) {
      success
      error
      emailTemplate {
        id
        name
        premium
        position
        live
        json
        screenshotUrl
        tags
        unrollTemplateId
        designFormat
        slug
        webflowSettings
      }
    }
  }
`

export default UPDATE_EMAIL_TEMPLATE