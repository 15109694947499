import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'

const MyTextField = (props) => {

  const { label, name, value='', callback, placeholder='', styles={}, type='number' } = props
  const [input, setInput] = useState(value)

  const onChange = (e) => {
    const { value } = e.target
    setInput(parseInt(value))
    callback && callback({[name]: parseInt(value)})
  }

  useEffect(() => {
    setInput(parseInt(value))
  },[value])
  
  return (
    <TextField
      label={label}
      type={type}
      placeholder={placeholder}
      value={input}
      onChange={onChange}
      margin="normal"
      variant="outlined"
      style={{...styles}}
      InputLabelProps={{
        shrink: true,
      }}
  />
  )
}
export default MyTextField