import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Script from "react-load-script";
import client from "./client";
import gql from "graphql-tag";
import { ApolloProvider, Query } from "react-apollo"
import Login from "./components/Login"
import Dashboard from "./components/Dashboard"
import Settings from "./components/Settings/index.js"
import Tags from "./components/Tags/index.js"
import LandingPageTemplates from './components/Templates/LandingPages'
import EmailTemplates from './components/Templates/Emails'
import SystemEmailTemplates from './components/Templates/SystemEmails'
import FormTemplates from './components/Templates/Forms'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import SettingsIcon from '@material-ui/icons/Settings';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { UserContext } from './UserContext'
import styled from 'styled-components';

const CustomLink = styled.a`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-size: 14px;
  color: #000000DE;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 12px 4px;
  width: 200px;
  justify-content: center;
`;

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  settings: {
    cursor: "pointer"
  }
};

const CURRENT_USER = gql`
  query getCurrentUser {
    me {
      id
      email
      canManageTemplates
      canManageSettings
      partner {
        id
        name
        appDomain
        variables {
          awsBucket
          awsAccessKeyId
        }
        tags
        platformFeatures
        meta
      }
    }
  }
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("mailmunch:token"),
      currentTab: window.location.pathname,
      anchorElProfile: null,
      anchorElSettings: null,
      value: window.location.pathname === '/' ? 'dashboard' : window.location.pathname.substring(1)
    }
  }

  render() {
    const { classes } = this.props
    const { token, anchorElProfile, anchorElSettings, value } = this.state

    const isProfileMenuOpen = Boolean(anchorElProfile)
    const isSettingsMenuOpen = Boolean(anchorElSettings)

    const renderProfileMenu = (
      <Menu
        anchorEl={anchorElProfile}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isProfileMenuOpen}
        onClose={this.handleProfileMenuClose}
      >
        <MenuItem onClick={() => this.logout()}>Logout</MenuItem>
      </Menu>
    )

    const renderSettingsMenu = (
      <Menu
        anchorEl={anchorElSettings}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSettingsMenuOpen}
        onClose={this.handleSettingsMenuClose}
      >
        <MenuItem onClick={this.handleSettings}>Settings</MenuItem>
        <MenuItem onClick={this.handleTags}>Manage Tags</MenuItem>
      </Menu>
    )

    return (
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider client={client}>
          {!token && <Login setToken={this.setToken.bind(this)} />}
          {token && (
            <Query query={CURRENT_USER}>
              {({ loading, error, data, refetch }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;

                return (
                  <UserContext.Provider value={data.me}>
                    <div>
                      <Script url="https://editor.unlayer.com/embed.js" />
                      <AppBar position="static">
                        <Toolbar>
                          <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.grow}
                          >
                            Partner Portal
                          </Typography>

                          <SettingsIcon
                            fontSize="small"
                            onClick={this.handleSettings}
                            style={styles.settings}
                          />
                          {token && data && data.me && (
                            <Button
                              color="inherit"
                              onClick={this.handleProfileMenuOpen}
                            >
                              {data.me.email}
                            </Button>
                          )}
                        </Toolbar>
                      </AppBar>

                      {renderProfileMenu}
                      {renderSettingsMenu}

                      <Router>
                        <Paper className={classes.grow}>
                          <Tabs
                            onChange={this.handleTabChange}
                            value={value}
                          >
                            <Tab
                              label="Dashboard"
                              component={(props)=>( <CustomLink href={props.to} >Dashboard</CustomLink> )}
                              to="/"
                              value="dashboard"
                            />
                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageSettings && (
                                <Tab
                                  label="Settings"
                                  component={Link}
                                  to="/settings"
                                  value="settings"
                                />
                              )}
                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageSettings && (
                                <Tab
                                  label="Tags"
                                  component={(props)=>( <CustomLink href={props.to} >Tags</CustomLink> )}
                                  to="/tags"
                                  value="tags"
                                />
                              )}

                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageTemplates && (
                                <Tab
                                  label="Form Templates"
                                  component={(props)=>( <CustomLink href={props.to} >Form Templates</CustomLink> )}
                                  to="/form_templates"
                                  value="form_templates"
                                />
                              )}
                            
                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageTemplates && (
                                <Tab
                                  label="Landing Page Templates"
                                  component={(props)=>( <CustomLink href={props.to} >Landing Page Templates</CustomLink> )}
                                  to="/landing_pages"
                                  value="landing_pages"
                                />
                              )}
                            
                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageTemplates && (
                                <Tab
                                  label="Email Templates"
                                  component={(props)=>( <CustomLink href={props.to} >Email Templates</CustomLink> )}
                                  to="/email_templates"
                                  value="email_templates"
                                />
                              )}

                            {token &&
                              data &&
                              data.me &&
                              data.me.canManageTemplates && (
                                <Tab
                                  label="System Email Templates"
                                  component={Link}
                                  to="/system_email_templates"
                                  value="system_email_templates"
                                />
                              )}
                            
                          </Tabs>
                        </Paper>

                        <Route
                          path="/"
                          exact
                          component={() => (
                            <Dashboard partner={data.me} />
                          )}
                        />

                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageSettings && (
                            <Route
                              path="/settings"
                              exact
                              component={props => (
                                <Settings {...props} partner={data.me} />
                              )}
                            />
                          )}

                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageSettings && (
                            <Route
                              path="/tags"
                              exact
                              component={props => (
                                <Tags {...props} partner={data.me.partner} />
                              )}
                            />
                          )}

                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageTemplates && (
                            <Route
                              path="/form_templates"
                              component={props => (
                                <FormTemplates
                                  {...props}
                                  partner={data.me.partner}
                                />
                              )}
                            />
                          )}

                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageTemplates && (
                            <Route
                              path="/landing_pages"
                              component={props => (
                                <LandingPageTemplates
                                  {...props}
                                  partner={data.me.partner}
                                />
                              )}
                            />
                          )}

                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageTemplates && (
                            <Route
                              path="/email_templates"
                              component={props => (
                                <EmailTemplates
                                  {...props}
                                  partner={data.me.partner}
                                />
                              )}
                            />
                          )}
                        
                        {token &&
                          data &&
                          data.me &&
                          data.me.canManageTemplates && (
                            <Route
                              path="/system_email_templates"
                              component={props => (
                                <SystemEmailTemplates
                                  {...props}
                                  partner={data.me.partner}
                                />
                              )}
                            />
                          )}
                      </Router>
                    </div>
                  </UserContext.Provider>
                );
              }}
            </Query>
          )}
        </ApolloProvider>
      </DndProvider>
    );
  }

  handleTabChange = (event, newValue) => {
    this.setState({ value: newValue })
  };

  handleSettings = (event) => {
    window.location.href = 'settings';
  }
  
  handleTags = (event) => {
    window.location.href = 'tags';
  }

  handleSettingsMenuOpen = (event) => {
     this.setState({ anchorElSettings: event.currentTarget })
  }
  
  handleSettingsMenuClose = () => {
    this.setState({ anchorElSettings: null })
  }
  
  handleProfileMenuOpen = (event) => {
     this.setState({ anchorElProfile: event.currentTarget })
  }
  
  handleProfileMenuClose = () => {
    this.setState({ anchorElProfile: null })
  }
  
  setToken = (token) => {
    localStorage.setItem('mailmunch:token', token)
    this.setState({ token })
  }
  
  logout = () => {
    localStorage.removeItem('mailmunch:token')
    this.setState({ token: '', anchorElProfile: null })
  }
}

export default withStyles(styles)(App);
