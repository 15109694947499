import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'

const MyTextField = (props) => {
  const { name, label, value: defaultValue = '', onChange, disabled = false, reset = 0 } = props
  const [value, setValue] = useState(defaultValue)
  const [time, setTime] = useState(0)

  useEffect(() => {
    setValue(defaultValue)
  },[defaultValue])

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      value={value}
      margin="dense"
      disabled={disabled}
      onChange={(e) => { 
        const value = e.target.value
        if (time) {
          clearTimeout(time)
        }
        setValue(value)
        setTime(setTimeout(() => {
          onChange({ name, value })
        }, 300, value))
      }}
      variant="outlined"
    />
  )
}

export default MyTextField