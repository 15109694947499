import React, { useState, useEffect, useCallback } from 'react'
import { post } from 'axios'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks';
import FilterBar from '../../common/filterBar'
import TemplateCard from '../../common/DraggableCard'
import update from 'immutability-helper'
import UPDATE_THEME from '../../../graphql/query/updateTheme'
import { CircularProgress, Grid } from '@material-ui/core'
import RepositionBar from '../../common/repositionBar'
import PixieImageEditor from '../../common/pixie'
import { actions, filterBy, sortBy } from './FilterConfig'
import { savePixieFileAndUpload } from '../../../utils/pixie';
import BULK_ASSIGN_TAGS_TO_THEME from '../../../graphql/mutation/bulkAssignTagsToTheme'
import BULK_REMOVE_TAGS_FROM_THEME from '../../../graphql/mutation/bulkRemoveTagsFromTheme'
import TagsModal from '../../common/TagsModal'

const DELETE_THEME = gql`
  mutation DeleteTheme($id: Int!) {
    deleteTheme(input: { id: $id }) {
      success
    }
  }
`;

const FormTemplates = (props) => {
  const { refetchTemplates, partner, callback, hasFilters, sorts, widgetTypes, filters, history } = props
  const { tags: { forms: formTags} } = partner 
  const [progress,setProgress] = useState({})
  const [updateTheme] = useMutation(UPDATE_THEME);
  const [loading, setLoading] = useState(false);
  const [bulkAssignTagsToTheme] = useMutation(BULK_ASSIGN_TAGS_TO_THEME);
  const [bulkRemoveTagsFromTheme] = useMutation(BULK_REMOVE_TAGS_FROM_THEME);
  const [selectedCards, setSelectedCards] = useState([]);
  
  const [state, setState] = useState({
    isReposition: false,
    maxWidth: 350,
    templates: props.templates
  })
  
  const { isReposition, templates } = state

  useEffect(() => {
    setState({ ...state, templates: props.templates })
  },[props.templates])
 
  const resetThemeImage = (id) => {
    updateTheme({
      variables: {
        id,
        screenshotUrl: null
      }
    })
  }
  
  const duplicateTheme = (template) => {
    history.push({
      pathname: `/form_templates/${template.id}/duplicate`,
      state: { templateId: template.id}
    })
  }

  const resetFilter = (reposition = false) => {
    callback(null, {}, true, reposition)
  }

  const updateState = (values, doUpdate = false, doRefetch = false) => {
    if(values.isReposition !== undefined)
    values.isReposition === true ? resetFilter(values.isReposition) : resetFilter()
    setState({ ...state, ...values, ...(doRefetch && { templates: props.templates })}) 
    if(doUpdate) {
      setLoading(true)
      Promise.all(
        templates.map(async ({id, position},index) => {
          await updateTheme({ variables: {
            id,
            position: index
          }})
      })).then(() => {
        refetchTemplates().then(() => setLoading(false))
      })
    }
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = templates[dragIndex]
      setState({
        ...state,
        templates:
        update(templates, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      })
    },
    [state],
  )

  const onSave = (file, name) => {
    const templateId = window.templateId
    const config = {
      onUploadProgress: progressEvent => {
        setProgress({ [templateId]: ((progressEvent.loaded/progressEvent.total) * 100) })
      }
    }
    
    savePixieFileAndUpload(file, name, partner, config)
      .then(response=>{
        const {url, key} = response;
        updateTheme({
          variables: {
            id: templateId,
            screenshotUrl: `${url}/${key}`
          }
        }).then((resp) => {
          refetchTemplates().then(() => {
            setProgress({ [templateId]: null })
          })
        })
      }).catch((err)=> {
        console.error(err)
      })
    
    window.pixie.close()
  }
  
  /**
 * event: { target: { value: { id, tags }, checked } }
 */
  const onCardSelect = useCallback(
    (event) => {
      const { value, checked } = event.target;
      const parsedValue = JSON.parse(value);
      if(checked) {
        selectedCards.push(parsedValue)
      } else {
        const selectedCardIndex = selectedCards.map(selectedCard => selectedCard.id).indexOf(parsedValue.id)
        selectedCards.splice(selectedCardIndex, 1)
      }
      
      setSelectedCards(selectedCards)
    },
  )
  
  const onBulkTagsAssign = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      themeIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkAssignTagsToTheme({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }

  const onBulkTagsRemove = (currentTags) => {
    const data = {
      tags:currentTags.map(currentTag => currentTag.value),
      themeIds: selectedCards.map(selectedCard => parseInt(selectedCard.id))
    }
    
    bulkRemoveTagsFromTheme({
      variables: data
    }).then((resp) => {
      refetchTemplates()
    })
  }

  return (
    <div style={{ position: 'relative'}}>
      <PixieImageEditor
        onSave={(file, name)=> onSave(file, name)}
      />
      <FilterBar 
        config={{ filterBy: filterBy(widgetTypes), actions, sortBy }} 
        callback={callback}
        disabled={isReposition}
        sorts={sorts}
        filters={filters}
        tagKey='forms'
      />
      
      <TagsModal 
        tags={formTags}
        selectedCards={selectedCards}
        onSave={onBulkTagsAssign}
        onBulkTagsRemove={onBulkTagsRemove}
      />
      
      <Grid container direction="row" spacing={2} style={{ padding: 8*3 }}>
        {
          loading ? <CircularProgress color="secondary" /> :
          <React.Fragment>
            <RepositionBar {...state} callback={updateState} resetFilter={resetFilter} hasFilters={hasFilters}/>
            <Mutation mutation={DELETE_THEME}>
            {(deleteTheme, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.deleteTheme && data.deleteTheme.success) {
                refetchTemplates()
              }

              const renderTemplates = templates.map((template,index) => {
                return (
                  <TemplateCard 
                    updateFunc={UPDATE_THEME}
                    to={'form_templates'}
                    key={template.id}
                    index={index}
                    id={template.id}
                    moveCard={moveCard}
                    deleteFunc={deleteTheme} 
                    duplicateFunc={duplicateTheme} 
                    template={template} 
                    isReposition={isReposition}
                    maxWidth={state.maxWidth}
                    progress={progress[template.id]}
                    resetThemeImage={resetThemeImage}
                    refetchTemplates={refetchTemplates}
                    usePixie={true}
                    onCardSelect={onCardSelect}
                  />
                )
              })

              return (
                <React.Fragment>
                {
                  renderTemplates 
                }
                </React.Fragment>
              )
            }}
            </Mutation>
          </React.Fragment>
        }
      </Grid>
    </div>
  )
}

export default FormTemplates