import gql from "graphql-tag"

const BULK_ASSIGN_TAGS_TO_THEME = gql`
  mutation bulkAssignTagsToTheme(
    $tags: [String]
    $themeIds: [Int]
  ) {
    bulkAssignTagsToTheme(input: {
      tags: $tags
      themeIds: $themeIds
    }) {
      success
      error
    }
  }
`

export default BULK_ASSIGN_TAGS_TO_THEME
