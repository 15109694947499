import React, { useState } from 'react';

import { post } from 'axios';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import uploadIcon from '../../assets/images/upload.svg';

import { parseLabel } from '../../utils'

const useStyles = makeStyles(theme => ({
  uploaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'flex-end',
  },
  heading: {
    color: '#82828C',
    fontSize: 14,
  },
  text: {
    color: '#82828C',
    fontSize: 10,
    marginTop: 10,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    width: 47,
    height: 30,
  },
  inputContainer: {
    display: 'flex',
    cursor: 'pointer',
    height: 75,
    width: 75,
    backgroundColor: '#F6F6F6',
    borderRadius: 5,
    border: '1px solid #BBBBBB',
    padding: '4px 4px',
    marginRight: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  previewContainer: {
    width: "auto",
    minWidth: 75,
    maxWidth: 350,
    height: 75,
    border: '1px dashed #707070',
    borderRadius: '5px',
    padding: 3,
    textAlign: 'center',
  },
  preview: {
    width: "auto",
    height: "auto",
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: 'contain'
  },
  imageContainer: {
    height: 50,
    width: 50,
    minWidth: 50,
    maxWidth: 50,
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
  },
  input: {
    display: 'none',
  },
}));

const Dropzone = ({name, handleImageChange, setUrls, partner, urls}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(false);
  const onDrop = acceptedFiles => {
    try 
    {
      if(acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const { id, appDomain, variables: { awsAccessKeyId, awsBucket } } = partner;
        const key = `partner_portal/partner/${id}/fileName/${file.name}`;
        setProgress(true);
        const conditions = [
          {acl: "public-read"},
          {bucket: `${awsBucket}`},
          {"Content-Type": "image/"},
          {success_action_status: "200"},
          {key: key},
          {"x-amz-meta-qqfilename": file.name},
          ["content-length-range", "0", "50000000"],
        ]
        post(`//${appDomain}/uploads/signature`, { conditions })
        .then(function (response) {
          const { data, status } = response
          const { policy, signature } = data
          const url = `https://${awsBucket}.s3.amazonaws.com`
          if(status === 200) {
            const formData = new FormData();
            formData.set('key', key)
            formData.set('AWSAccessKeyId',`${awsAccessKeyId}`)
            formData.set('Content-Type', 'image/png');
            formData.set('success_action_status', '200');
            formData.set('acl', 'public-read');
            formData.set('x-amz-meta-qqfilename', file.name);
            formData.set('policy',policy)
            formData.set('signature',signature)
            formData.set("file", file, file.name);
            post(url, formData).then((response) => {
              const finalFileUrl = `${url}/${key}`
              setUrls({
                ...urls,
                [name]: { url: finalFileUrl }
              });
              setProgress(false);
              handleImageChange(name, finalFileUrl)
            })
          }
        })
        .catch(function (error) {
          console.log(error)
        });
      }
    } catch(e) {
      console.log('error',e)
    }
  }
    
  const { getRootProps, getInputProps } = useDropzone({onDrop});
  
  return (
    <div className={classes.container}>
      <div className={classes.inputContainer} {...getRootProps()}>
        {
          !progress ?
          <>
            <input id='file-upload' style={{ display: 'none' }} {...getInputProps()} accept="image/png, image/jpeg, image/gif"/>
            <div htmlFor="file-upload" className={classes.textContainer}>
              <img className={classes.icon} src={uploadIcon} />
              <div className={classes.text}>JPEG, PNG, GIF</div>
            </div>
          </> :
          <CircularProgress />
        }
      </div>
      <div className={classes.previewContainer}>
        <img
          alt="preview"
          className={classes.preview}
          src={urls[name].url || "https://via.placeholder.com/100x75.png?text=LOGO"} /> 
      </div>
    </div>
  );
}

const Logos = ({ logosGroup, partner, handleImageChange }) => {
  const classes = useStyles();
  const [urls, setUrls] = useState(logosGroup);
  
  
  return (
    <div className={classes.uploaderContainer}>
    {
      Object.keys(logosGroup).map((logo, index) => {
        return (
          <div key={`logo-${index}`} >
            <Typography variant="overline" display="block" gutterBottom>
              {parseLabel(logo.toUpperCase())}
            </Typography>
            <Dropzone
              name={logo}
              handleImageChange={handleImageChange}
              setUrls={setUrls}
              partner={partner}
              urls={urls}
            />
          </div>
        );
      })
    }
    </div>
  );
};

export default Logos;
