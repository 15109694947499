import gql from "graphql-tag"

const BULK_ASSIGN_TAGS_TO_EMAIL_TEMPLATES = gql`
  mutation bulkAssignTagsToEmailTemplates(
    $tags: [String]
    $emailTemplateIds: [Int]
  ) {
    bulkAssignTagsToEmailTemplates(input: {
      tags: $tags
      emailTemplateIds: $emailTemplateIds
    }) {
      success
      error
    }
  }
`

export default BULK_ASSIGN_TAGS_TO_EMAIL_TEMPLATES
