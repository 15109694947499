import gql from "graphql-tag"

const BULK_REMOVE_TAGS_FROM_EMAIL_TEMPLATES = gql`
  mutation bulkRemoveTagsFromEmailTemplates(
    $tags: [String]
    $emailTemplateIds: [Int]
  ) {
    bulkRemoveTagsFromEmailTemplates(input: {
      tags: $tags
      emailTemplateIds: $emailTemplateIds
    }) {
      success
      error
    }
  }
`

export default BULK_REMOVE_TAGS_FROM_EMAIL_TEMPLATES
