import gql from "graphql-tag"

const DELETE_SLUG = gql`
  mutation deleteSlug($partnerId: Int!, $slugKey: String!, $slugName: String!) {
    deleteGlobalSlugFromPartner(input: { partnerId: $partnerId, slugKey: $slugKey, slugName: $slugName }) {
      success
      error
      partner {
        id
        meta
      }
    }
  }
`;

export default DELETE_SLUG