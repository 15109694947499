import React from 'react'
import { Redirect } from "react-router-dom"

import gql from "graphql-tag"
import { Mutation, Query } from "react-apollo"

import Editor from './Editor'

import FETCH_SYSTEM_EMAIL_TEMPLATES_COUNT from '../../../graphql/query/fetchEmailTemplatesCount'

const CREATE_SYSTEM_EMAIL_TEMPLATE = gql`
  mutation CreateSystemEmailTemplate($name: String!, $position: Int, $slug: String ,$json: JSON, $html: String, $tags: JSON) {
    createSystemEmailTemplate(input: { name: $name, position: $position, slug: $slug ,json: $json, html: $html, tags: $tags }) {
      success
      systemEmailTemplate {
        id
        name
        position
        slug
        json
        tags
      }
    }
  }
`;

export default class NewTemplate extends React.Component {
  render() {
    const { refetchTemplates, templates, slugs } = this.props

    return (
      <Query query={FETCH_SYSTEM_EMAIL_TEMPLATES_COUNT} fetchPolicy='network-only'>
        {({ loading, error, data , refetch, ...props }) => {
          if (!data && loading) return "Loading Template..."
          if (error) return `Error! ${error.message}`
          const { totalEmailTemplatesCount: templatesCount } = data
          
          return (
            <Mutation mutation={CREATE_SYSTEM_EMAIL_TEMPLATE}>
            {(createEmailTemplate, { error, loading, data }) => {
              if (loading) return <div>Loading...</div>
              if (error) return <div>There was an error.</div>
              if (data && data.createSystemEmailTemplate && data.createSystemEmailTemplate.success) {
                refetchTemplates()
                return <Redirect to="/system_email_templates" />
              }

              return (
                <Editor templates={templates} templatesCount={templatesCount} slugs={slugs} onSave={(data) => {
                  createEmailTemplate({
                    variables: {
                      html: data.html,
                      json: data.json,
                      name: data.name,
                      position: data.position,
                      slug: data.slug === "none" ? null : data.slug,
                      tags: data.tags
                    },
                    refetchQueries: [
                      'getCurrentUser'
                    ]
                  })
                }} />
              )
            }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}
