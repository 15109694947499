import React, { useRef, useState } from 'react'
import ItemTypes from './ItemTypes'
import { useDrag, useDrop } from 'react-dnd'
import { Link } from "react-router-dom"
import { Mutation } from "react-apollo"
import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography,Checkbox } from '@material-ui/core'
import CheckBoxWithConfirm from '../input/checkboxWithConfirm'
import Pixie from './Pixie'
import SnackBar from "../../common/SnackBar"

const DragableCard = ({ id, index, moveCard, template, isReposition, deleteFunc, maxWidth, progress = 0, resetThemeImage, refetchTemplates, usePixie = false, updateFunc, to, duplicateFunc, toggleInputs = true, onCardSelect, webflowEnabled }) => {
  const { live, premium, slug, webflowSettings, tags, name } = template
  const inputs = [
    { label: 'Premium', name: 'premium', value: premium },
    { label: 'Live', name: 'live', value: live },
    //...((webflowSettings !== undefined && webflowEnabled) ? [{ label: 'Web Gallery', name: 'webflowSettings', value: Object.keys(webflowSettings || {}).length > 0 ? true : false }] : []),
  ]
  const ref = useRef(null)

  //for showing snackbar error
  const [updateError, setUpdateError] = useState(false)
  const [updateErrorMsg, setUpdateErrorMsg] = useState("")

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the right
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      let drag = false
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
        drag = true
      }
      
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
        drag = true
      }
      
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging rightwards
      if (dragIndex < hoverIndex && hoverClientX > hoverMiddleX) {
        drag = true
      }
      // Dragging leftwards
      if (dragIndex > hoverIndex && hoverClientX < hoverMiddleX) {
        drag = true
      }

      if(drag) {
      // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      }
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  isReposition && drag(drop(ref))

  const renderInputs = inputs.map((item,index) => {
    return (
      <Mutation mutation={updateFunc} key={index}>
      {(update, { error, loading, data }) => {
        return (
          <Grid item xs>
            {
              <CheckBoxWithConfirm {...item} loading={loading} callback={(data) => {
                update({
                  variables: {
                    id,
                    ...data,
                  }
                }).then((resp) => {
                  if(!resp.data.updateEmailTemplate.success){
                    setUpdateError(true)
                    setUpdateErrorMsg(resp.data.updateEmailTemplate.error)
                  }
                  else {
                    refetchTemplates()
                  }
                })
              }}
              />
            }
          </Grid>
        )
      }}
      </Mutation>
    )
  });
  
  const getScreenshotUrl = () => {
    switch (to) {
      case 'email_templates':
        return `${template.screenshotUrl}&size=fullpage`
        break;
      case 'landing_pages':
        return `${template.screenshotUrl}&size=fullpage`
        break;
      default:
        return template.screenshotUrl
        break;
    }
  }
  
  const onSelect = (event) => {
    if(typeof(onCardSelect) === 'function') {
      onCardSelect(event)
    }
  }

  return (
    <Grid key={`template-${id}`} ref={ref} item xs={6} sm={4}  style={{ maxWidth }}>
      <SnackBar state={updateError} error={updateErrorMsg} handleClose={() => setUpdateError(false)}/>
      <Card style={{ ...(isReposition && { cursor: 'move'}) , opacity }}>
        <div style={{position: 'absolute', zIndex: 10}}>
          <Checkbox onChange={onSelect} value={JSON.stringify({id, tags: template.tags})}/>
        </div>
        {
          isReposition === false && usePixie ?
          <Pixie id={template.id} progress={progress} image={getScreenshotUrl()} onReset={resetThemeImage}>
            <CardMedia
              style={{ width: '100%', height: 0, paddingTop: '100%' }}
              image={getScreenshotUrl()}
              title={template.name}
            />
          </Pixie> :
          <CardMedia
            style={{ width: '100%', height: 0, paddingTop: '100%', ...(isReposition && template.live === false && { opacity: 0.3 })}}
            image={getScreenshotUrl()}
            title={template.name}
          />
        }
        {
          isReposition === false &&
          <React.Fragment>
            <CardContent>
              <Grid container spacing={2} wrap="nowrap" justify="space-between">
                <Grid item xs zeroMinWidth>
                  <Typography gutterBottom variant="h5" component="h2" noWrap={true}>
                    {template.name}
                  </Typography>
                </Grid>
                <Grid item> { 
                  template.designFormat && (template.designFormat === 'simple' || template.designFormat === 'drag_drop') &&
                    <Chip
                      label={ template.designFormat === 'drag_drop' ? <Typography>D</Typography> : <Typography>S</Typography> }
                      color={ template.designFormat === 'drag_drop' ? 'primary' : 'secondary' }
                      style={{ borderRadius: 5 }}
                    />
                  }
                </Grid>
              </Grid>
                
              <Grid container alignItems="center" justify="center">
                { toggleInputs && renderInputs }
              </Grid>
              {
                slug &&
                <div style={styles.slug}>
                  <div style={styles.slugText}>{slug}</div>
                </div>   
              }
              <div style={styles.tagContainer}>
                {(template.tags && template.tags.map((tag,index) => {
                  return <div key={index} style={styles.tag}><div style={styles.tagText}>{tag}</div></div>
                }))}
              </div>
            </CardContent>
            <CardActions>
              <Button color="primary" size="small" component={Link} to={`/${to}/${template.id}/edit`}>Edit</Button>
              {template.designFormat && template.designFormat === "drag_drop" && <Button color="primary" size="small" onClick={() => {
                if (window.confirm("Are you sure you want to create a duplicate of this template?")) {
                  duplicateFunc(template)
                }
              }}>Duplicate</Button>}
              <Button color="secondary" size="small" onClick={() => {
                if (window.confirm("Are you sure you want to delete this template?")) {
                  deleteFunc({
                    variables: {
                      id: template.id
                    }
                  })
                }
              }}>Delete</Button>
            </CardActions>
          </React.Fragment>
        }
      </Card>
    </Grid>
  )
}

const styles = {
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'sans-serif',
  },
  tag: {
    backgroundColor: '#DBE4EF',
    borderRadius: 5,
    display: 'flex',
    margin: 2,
  },
  tagText: {
    color: '#5b87b9',
    padding: 6,
    whiteSpace: 'nowrap',
    borderRadius: 5,
    fontSize: '85%',
  },
  slug:{
    backgroundColor: 'hsl(0,0%,90%)',
    borderRadius: 5,
    display: 'flex',
    margin: "2px 2px 10px 2px",
    width:'fit-content'
  },
  slugText:{
    backgroundColor: 'hsl(0,0%,90%)',
    borderRadius: 5,
    fontFamily:"monospace",
    display: 'flex',
    margin: 6,
    width:'fit-content'
  }
}

export default DragableCard
