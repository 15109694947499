import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import { InputLabel, FormControl } from '@material-ui/core'
import { TagOptions } from '../constants'

const MyReactSelect = (props) => {
  const { name, label, value: defaultValue = null, onChange, disabled = false, tagKey = 'forms' } = props
  const [value, setValue] = useState(defaultValue) 
  const inputLabel = useRef(null)
  const tagOptions = TagOptions(tagKey)

  useEffect(()=> {
    setValue(defaultValue || [])
  },[defaultValue])

  const handleChange = (newValue, actionMeta) => {
    const value = newValue ? newValue.map(item => {
      return item.value
    }) : []
    setValue(value)
    onChange({ name, value: value.length > 0 ? value: '' })
  };

  return (
    <FormControl style={{ minWidth: 120 }}>
      <InputLabel className="MuiInputLabel-shrink" ref={inputLabel}>
        { label }
      </InputLabel>
      <Select
        isMulti
        onChange={handleChange}
        value={value && value.map(item => ({ value: item, label: item }))}
        isDisabled={disabled}
        options={tagOptions}
        styles={{ 
          control: styles => ({ ...styles, 
            margin: 0,
            marginTop: 16,
            border: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 0,
            width: 195,
            minHeight: 32,
            boxShadow: 'none',
            ':hover': {
              borderColor: 'rgba(0, 0, 0, 0.42)',
            },
          }),
          container: styles => ({
            fontFamily: 'sans-serif'
          }),
          indicatorsContainer: styles => ({
            display: 'none',
          }),
          placeholder: styles => ({
            display: 'none',
          }),
        }}
      />
    </FormControl>
  )
}

export default MyReactSelect