import gql from "graphql-tag"

const ADD_SLUG = gql`
  mutation addSlug($partnerId: Int!, $slugKey: String!, $slugName: String!) {
    addGlobalSlugToPartner(input: { partnerId: $partnerId, slugKey: $slugKey, slugName: $slugName }) {
      success
      error
      partner {
        id
        meta
      }
    }
  }
`;

export default ADD_SLUG