import React from 'react'
import { Route } from "react-router-dom"
import _ from 'lodash'
import gql from "graphql-tag"
import { Query } from "react-apollo"

import Templates from './Templates'
import EditTemplate from './EditTemplate'
import NewTemplate from './NewTemplate'

const SYSTEM_EMAIL_TEMPLATES = gql`
query SystemEmailTempalates($filterBy: SystemEmailTemplateFilterInput,$sortBy: SystemEmailTemplateSortBy) {
  systemEmailTemplates(filterBy: $filterBy, sortBy: $sortBy){
    id
    name
    position
    slug
    screenshotUrl
    json
    tags
  }
}
`;

export default class SystemEmailTemplates extends React.Component {

  default = {
    filterBy: {
    },
    sortBy: 'POSITION',
    count: 0,
  }

  state = { ...this.default }

  callback = (index, { name, value }, reset = false, reposition = false) => {
    const { filterBy, count } = this.state
    if(reset) {
      this.setState({...this.default, count: count+1 })
    }
    else {
      if(index === 'filterBy') {
        if ( value !== '' )
          this.setState({ filterBy: { ...filterBy, [name]: value }})
        else
        {
          let newFilterBy = { ...filterBy }
          delete newFilterBy[name]
          this.setState({ filterBy: newFilterBy })
        }
      }
      if(index === 'sortBy') {
        this.setState({ sortBy: value })
      }
    }
  }

  hasFilters = () => {
    const { filterBy, sortBy } = this.state
    const { ...stateFilterBy } = filterBy
    const { ...defaultFilterBy } = this.default.filterBy
    const isEqual = _.isEqual(stateFilterBy,defaultFilterBy)

    if(!isEqual || this.default.sortBy !== sortBy )
    {
      return true
    }
    else
      return false
  }

  render() {
    const { match, partner: { meta } } = this.props
    const { filterBy: filters, sortBy: sorts } = this.state

    return (
      <Query query={SYSTEM_EMAIL_TEMPLATES} variables={this.state} fetchPolicy='network-only'>
        {({ loading, error, data, refetch }) => {
          if (!data && loading) return "Loading Templates..."
          if (error) return `Error! ${error.message}`
          return (
            <div>
              <Route exact path={`${match.path}`} render={(props) => <Templates {...props} templates={data.systemEmailTemplates} refetchTemplates={refetch} sorts={sorts} filters={filters} callback={this.callback} hasFilters={this.hasFilters()} />}  />
              <Route path={`${match.path}/new`} render={(props) => <NewTemplate {...props} templates={data.systemEmailTemplates} refetchTemplates={refetch} slugs={meta?.slugs?.systemEmails}  />} />
              <Route path={`${match.path}/:id/edit`} render={(props) => <EditTemplate {...props} templates={data.systemEmailTemplates} refetchTemplates={refetch} slugs={meta?.slugs?.systemEmails} />} />
            </div>
          )
        }}
      </Query>
    )
  }
}
 