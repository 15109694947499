
import { useContext } from 'react'
import { UserContext } from '../../UserContext'

const getTagOptions = (user,key) => {
  const { partner: { tags = {} } = {} } = user
  const tagOptions = tags[key] || []
  const options = tagOptions.map((tag,index) => ({ id: index, label: tag, value: tag }))
  return options
}

const TagOptions = (key) => {
  const user = useContext(UserContext);
  return getTagOptions(user,key)
}

export { TagOptions, getTagOptions }