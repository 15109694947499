import gql from "graphql-tag"

const FETCH_LANDING_PAGE_TEMPLATE = gql`
query LandingPageTemplate($id: Int!) {  
  landingPageTemplate(id: $id) {
    id
    name
    premium
    position
    live
    screenshotUrl
    tags
    pages {
      id
      position
      unlayerId
      json
      html
      screenshotUrl
      css
    }
  }
}
`

export default FETCH_LANDING_PAGE_TEMPLATE