import React, { useState, useEffect, useCallback } from 'react'

import './styles.min.css'
import './scripts.min.js'

const PixieImageEditor = (props) => {
  const { onSave, id } = props

  useEffect(() => {
    if(window.pixie) {
      delete window.pixie;
    }
    
    const config = {
      baseUrl: 'https://cf.mailmunch.com/pixie',
      crossOrigin: true,
      ui: {
        mode: 'overlay',
        visible: false,
        openImageDialog: false,
        showExportPanel: false,
        toolbar: {
          showCloseButton: true,
        },
      },
      onLoad: () => {
      },
      onSave: (file, name) => {
        onSave(file, name)
      },
    };
    window.pixie = new window.Pixie(config);
  },[onSave, id])

  return (
    <div style={{ position: 'relative'}}>
      <pixie-editor>
      </pixie-editor>
    </div>
  )
}

export default PixieImageEditor